
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _404lYow5jLfSUUcrJWhxCLTZp0cP2x3zTQDfrMf4QFFvO4Meta } from "/build/src/pages/404.vue?macro=true";
import { default as _500xoLUBSDsEtPQXdH6HtHY3ITEIsELqR_454Dmm4mpdh3n4Meta } from "/build/src/pages/500.vue?macro=true";
import { default as indexvx0_45_FbkduesUmyyx5cjjMeos49Pq1wRCx8HghsjEy4Meta } from "/build/src/pages/index.vue?macro=true";
import { default as bfJpctSeIL9nIebTxIKAlxHMnAEuBJTNVMcUulZ_45AauyMMeta } from "/build/src/pages/_debug/bf.vue?macro=true";
import { default as indexhWCKqreG_py_Q7PZ5kg5SUxqF3eHEesclmaLLx_IgogMeta } from "/build/src/pages/_debug/index.vue?macro=true";
import { default as indexi_Chy6zCRnV6w4K0bNZqUgn7RdjiSq_457HsFAImx4Z4EMeta } from "/build/src/pages/sitemap/index.vue?macro=true";
import { default as indexGuFywCpR9B0tz5g1X0B_45joGsk_45TBp1l_454v7txnAbViIMeta } from "/build/src/pages/guarantee/index.vue?macro=true";
import { default as indexs2vJ7X8jWAiVFDkWpCUll4i_45QFgR8mcbPcnhtVar2xwMeta } from "/build/src/pages/backpackers/index.vue?macro=true";
import { default as indexZhrhd1awBhhWMeL69gE1DnViIgcxuBlQ8Ik5F_SRThYMeta } from "/build/src/pages/cookiespolicy/index.vue?macro=true";
import { default as indexT6362h3eQvKkSsZv_0ra_45bElo2iYJDNKahBBn5wPzg0Meta } from "/build/src/pages/[propertyType]/index.vue?macro=true";
import { default as indexvhtSugEUPXohoB3FPlJ8D9FAs17bykG54JE82oTcGIAMeta } from "/build/src/pages/guarantee/terms/index.vue?macro=true";
import { default as index6DvN4C7z0V187cMa4UVFYIY_45EUAYPuD5UKP_45WFDRZ40Meta } from "/build/src/pages/securityprivacy/index.vue?macro=true";
import { default as indexbFrMALOkUdGbjZTxVmZT99qZsdORyVhSYxxkNKJpq9wMeta } from "/build/src/pages/website-testing/index.vue?macro=true";
import { default as indexNwWLmgEHdjWUEJszq5ADmYd2gsph2EWL3kvgkYfDxfIMeta } from "/build/src/pages/reviewguidelines/index.vue?macro=true";
import { default as indexuVZO3m2V2_45aDRngPMqvgm9BXw__Q4Txr2VJ6vhZgFrwMeta } from "/build/src/pages/legal/[legalPage]/index.vue?macro=true";
import { default as indexuXNufOfaDNW95lnglKOXFJ1V93RhfVa62OIDFWoJ3F4Meta } from "/build/src/pages/bestpriceguarantee/index.vue?macro=true";
import { default as indexXhO9ncyhb1Y05cld5fCKtblUyOldYGRLQA_HN9ukNmkMeta } from "/build/src/pages/deals/black-friday/index.vue?macro=true";
import { default as index_8ahHc3_454fmmjYYBV6Pvi1ypZTk17e_45qvYfd3mWljAoMeta } from "/build/src/pages/hostel-group/[brand]/index.vue?macro=true";
import { default as indexaefYz8mWg88ZyDekRNpW7wGjT0qfVAZOrDfm2wopEyMMeta } from "/build/src/pages/coupons-terms-conditions/index.vue?macro=true";
import { default as indexptv51Al2hDUK2HiMoRmnQscGoqa5LzaROM_453ckPDH54Meta } from "/build/src/pages/hosteltermsandconditions/index.vue?macro=true";
import { default as indexBmKWdVP4tR0aJoWkuLs0v589JlQCMwZvB_45qnU2DhiOoMeta } from "/build/src/pages/vouchertermsandconditions/index.vue?macro=true";
import { default as indexzqzss_45u7_45jPSVMfSvVZ0_45hobutEpyDTj7bOZCV_gsu0Meta } from "/build/src/pages/[propertyType]/[continent]/index.vue?macro=true";
import { default as index20L4wsqXaM8nB9d4MLwySKhmaSz_45hsApvcxGPsME82IMeta } from "/build/src/pages/groupsdataprotectionnotice/index.vue?macro=true";
import { default as index8xw5KTONVpRpd5EAeYICifOO_sMvvZdcx661NCPmr0kMeta } from "/build/src/pages/[propertyType]/[continent]/[country]/index.vue?macro=true";
import { default as index_45uaohzGVppUwLqxQRI2Getog_FRCD6UqUIOAIa4W1b4Meta } from "/build/src/pages/[propertyType]/[continent]/[country]/[city]/index.vue?macro=true";
import { default as indexhOrgrA6xMAc6QGCGwBGmYE4Ga7bv_L4vKKqyCjTcFNcMeta } from "/build/src/pages/[propertyType]/p/[propertyId]/[propertyName]/index.vue?macro=true";
import { default as indexgehijKBjuf5w20vPvVz43I5uFgQ_45b006wWmrTq3fDTcMeta } from "/build/src/pages/[propertyType]/[continent]/[country]/a/[area]/index.vue?macro=true";
import { default as indexoRUrXH6EfRiax1vphzwYgC5zJzQrtk9Kz1uczWAbNF8Meta } from "/build/src/pages/[propertyType]/[continent]/[country]/r/[region]/index.vue?macro=true";
import { default as indexzXFRgYiZfyLgpwwVQ14f8C1WB_4S0_45B76G805XD81xAMeta } from "/build/src/pages/[propertyType]/[continent]/[country]/[city]/d/[district]/index.vue?macro=true";
export default [
  {
    name: "404___en",
    path: "/404/",
    meta: _404lYow5jLfSUUcrJWhxCLTZp0cP2x3zTQDfrMf4QFFvO4Meta || {},
    component: () => import("/build/src/pages/404.vue")
  },
  {
    name: "404___fr",
    path: "/fr/404/",
    meta: _404lYow5jLfSUUcrJWhxCLTZp0cP2x3zTQDfrMf4QFFvO4Meta || {},
    component: () => import("/build/src/pages/404.vue")
  },
  {
    name: "404___de",
    path: "/de/404/",
    meta: _404lYow5jLfSUUcrJWhxCLTZp0cP2x3zTQDfrMf4QFFvO4Meta || {},
    component: () => import("/build/src/pages/404.vue")
  },
  {
    name: "404___es",
    path: "/es/404/",
    meta: _404lYow5jLfSUUcrJWhxCLTZp0cP2x3zTQDfrMf4QFFvO4Meta || {},
    component: () => import("/build/src/pages/404.vue")
  },
  {
    name: "404___it",
    path: "/it/404/",
    meta: _404lYow5jLfSUUcrJWhxCLTZp0cP2x3zTQDfrMf4QFFvO4Meta || {},
    component: () => import("/build/src/pages/404.vue")
  },
  {
    name: "404___pt",
    path: "/pt/404/",
    meta: _404lYow5jLfSUUcrJWhxCLTZp0cP2x3zTQDfrMf4QFFvO4Meta || {},
    component: () => import("/build/src/pages/404.vue")
  },
  {
    name: "404___br",
    path: "/br/404/",
    meta: _404lYow5jLfSUUcrJWhxCLTZp0cP2x3zTQDfrMf4QFFvO4Meta || {},
    component: () => import("/build/src/pages/404.vue")
  },
  {
    name: "404___sv",
    path: "/sv/404/",
    meta: _404lYow5jLfSUUcrJWhxCLTZp0cP2x3zTQDfrMf4QFFvO4Meta || {},
    component: () => import("/build/src/pages/404.vue")
  },
  {
    name: "404___pl",
    path: "/pl/404/",
    meta: _404lYow5jLfSUUcrJWhxCLTZp0cP2x3zTQDfrMf4QFFvO4Meta || {},
    component: () => import("/build/src/pages/404.vue")
  },
  {
    name: "404___fi",
    path: "/fi/404/",
    meta: _404lYow5jLfSUUcrJWhxCLTZp0cP2x3zTQDfrMf4QFFvO4Meta || {},
    component: () => import("/build/src/pages/404.vue")
  },
  {
    name: "404___da",
    path: "/da/404/",
    meta: _404lYow5jLfSUUcrJWhxCLTZp0cP2x3zTQDfrMf4QFFvO4Meta || {},
    component: () => import("/build/src/pages/404.vue")
  },
  {
    name: "404___nl",
    path: "/nl/404/",
    meta: _404lYow5jLfSUUcrJWhxCLTZp0cP2x3zTQDfrMf4QFFvO4Meta || {},
    component: () => import("/build/src/pages/404.vue")
  },
  {
    name: "404___no",
    path: "/no/404/",
    meta: _404lYow5jLfSUUcrJWhxCLTZp0cP2x3zTQDfrMf4QFFvO4Meta || {},
    component: () => import("/build/src/pages/404.vue")
  },
  {
    name: "404___cs",
    path: "/cs/404/",
    meta: _404lYow5jLfSUUcrJWhxCLTZp0cP2x3zTQDfrMf4QFFvO4Meta || {},
    component: () => import("/build/src/pages/404.vue")
  },
  {
    name: "404___ru",
    path: "/ru/404/",
    meta: _404lYow5jLfSUUcrJWhxCLTZp0cP2x3zTQDfrMf4QFFvO4Meta || {},
    component: () => import("/build/src/pages/404.vue")
  },
  {
    name: "404___tr",
    path: "/tr/404/",
    meta: _404lYow5jLfSUUcrJWhxCLTZp0cP2x3zTQDfrMf4QFFvO4Meta || {},
    component: () => import("/build/src/pages/404.vue")
  },
  {
    name: "404___zh",
    path: "/zh/404/",
    meta: _404lYow5jLfSUUcrJWhxCLTZp0cP2x3zTQDfrMf4QFFvO4Meta || {},
    component: () => import("/build/src/pages/404.vue")
  },
  {
    name: "404___ja",
    path: "/ja/404/",
    meta: _404lYow5jLfSUUcrJWhxCLTZp0cP2x3zTQDfrMf4QFFvO4Meta || {},
    component: () => import("/build/src/pages/404.vue")
  },
  {
    name: "404___ko",
    path: "/ko/404/",
    meta: _404lYow5jLfSUUcrJWhxCLTZp0cP2x3zTQDfrMf4QFFvO4Meta || {},
    component: () => import("/build/src/pages/404.vue")
  },
  {
    name: "500___en",
    path: "/500/",
    meta: _500xoLUBSDsEtPQXdH6HtHY3ITEIsELqR_454Dmm4mpdh3n4Meta || {},
    component: () => import("/build/src/pages/500.vue")
  },
  {
    name: "500___fr",
    path: "/fr/500/",
    meta: _500xoLUBSDsEtPQXdH6HtHY3ITEIsELqR_454Dmm4mpdh3n4Meta || {},
    component: () => import("/build/src/pages/500.vue")
  },
  {
    name: "500___de",
    path: "/de/500/",
    meta: _500xoLUBSDsEtPQXdH6HtHY3ITEIsELqR_454Dmm4mpdh3n4Meta || {},
    component: () => import("/build/src/pages/500.vue")
  },
  {
    name: "500___es",
    path: "/es/500/",
    meta: _500xoLUBSDsEtPQXdH6HtHY3ITEIsELqR_454Dmm4mpdh3n4Meta || {},
    component: () => import("/build/src/pages/500.vue")
  },
  {
    name: "500___it",
    path: "/it/500/",
    meta: _500xoLUBSDsEtPQXdH6HtHY3ITEIsELqR_454Dmm4mpdh3n4Meta || {},
    component: () => import("/build/src/pages/500.vue")
  },
  {
    name: "500___pt",
    path: "/pt/500/",
    meta: _500xoLUBSDsEtPQXdH6HtHY3ITEIsELqR_454Dmm4mpdh3n4Meta || {},
    component: () => import("/build/src/pages/500.vue")
  },
  {
    name: "500___br",
    path: "/br/500/",
    meta: _500xoLUBSDsEtPQXdH6HtHY3ITEIsELqR_454Dmm4mpdh3n4Meta || {},
    component: () => import("/build/src/pages/500.vue")
  },
  {
    name: "500___sv",
    path: "/sv/500/",
    meta: _500xoLUBSDsEtPQXdH6HtHY3ITEIsELqR_454Dmm4mpdh3n4Meta || {},
    component: () => import("/build/src/pages/500.vue")
  },
  {
    name: "500___pl",
    path: "/pl/500/",
    meta: _500xoLUBSDsEtPQXdH6HtHY3ITEIsELqR_454Dmm4mpdh3n4Meta || {},
    component: () => import("/build/src/pages/500.vue")
  },
  {
    name: "500___fi",
    path: "/fi/500/",
    meta: _500xoLUBSDsEtPQXdH6HtHY3ITEIsELqR_454Dmm4mpdh3n4Meta || {},
    component: () => import("/build/src/pages/500.vue")
  },
  {
    name: "500___da",
    path: "/da/500/",
    meta: _500xoLUBSDsEtPQXdH6HtHY3ITEIsELqR_454Dmm4mpdh3n4Meta || {},
    component: () => import("/build/src/pages/500.vue")
  },
  {
    name: "500___nl",
    path: "/nl/500/",
    meta: _500xoLUBSDsEtPQXdH6HtHY3ITEIsELqR_454Dmm4mpdh3n4Meta || {},
    component: () => import("/build/src/pages/500.vue")
  },
  {
    name: "500___no",
    path: "/no/500/",
    meta: _500xoLUBSDsEtPQXdH6HtHY3ITEIsELqR_454Dmm4mpdh3n4Meta || {},
    component: () => import("/build/src/pages/500.vue")
  },
  {
    name: "500___cs",
    path: "/cs/500/",
    meta: _500xoLUBSDsEtPQXdH6HtHY3ITEIsELqR_454Dmm4mpdh3n4Meta || {},
    component: () => import("/build/src/pages/500.vue")
  },
  {
    name: "500___ru",
    path: "/ru/500/",
    meta: _500xoLUBSDsEtPQXdH6HtHY3ITEIsELqR_454Dmm4mpdh3n4Meta || {},
    component: () => import("/build/src/pages/500.vue")
  },
  {
    name: "500___tr",
    path: "/tr/500/",
    meta: _500xoLUBSDsEtPQXdH6HtHY3ITEIsELqR_454Dmm4mpdh3n4Meta || {},
    component: () => import("/build/src/pages/500.vue")
  },
  {
    name: "500___zh",
    path: "/zh/500/",
    meta: _500xoLUBSDsEtPQXdH6HtHY3ITEIsELqR_454Dmm4mpdh3n4Meta || {},
    component: () => import("/build/src/pages/500.vue")
  },
  {
    name: "500___ja",
    path: "/ja/500/",
    meta: _500xoLUBSDsEtPQXdH6HtHY3ITEIsELqR_454Dmm4mpdh3n4Meta || {},
    component: () => import("/build/src/pages/500.vue")
  },
  {
    name: "500___ko",
    path: "/ko/500/",
    meta: _500xoLUBSDsEtPQXdH6HtHY3ITEIsELqR_454Dmm4mpdh3n4Meta || {},
    component: () => import("/build/src/pages/500.vue")
  },
  {
    name: "index___en",
    path: "/",
    component: () => import("/build/src/pages/index.vue")
  },
  {
    name: "index___fr",
    path: "/fr/",
    component: () => import("/build/src/pages/index.vue")
  },
  {
    name: "index___de",
    path: "/de/",
    component: () => import("/build/src/pages/index.vue")
  },
  {
    name: "index___es",
    path: "/es/",
    component: () => import("/build/src/pages/index.vue")
  },
  {
    name: "index___it",
    path: "/it/",
    component: () => import("/build/src/pages/index.vue")
  },
  {
    name: "index___pt",
    path: "/pt/",
    component: () => import("/build/src/pages/index.vue")
  },
  {
    name: "index___br",
    path: "/br/",
    component: () => import("/build/src/pages/index.vue")
  },
  {
    name: "index___sv",
    path: "/sv/",
    component: () => import("/build/src/pages/index.vue")
  },
  {
    name: "index___pl",
    path: "/pl/",
    component: () => import("/build/src/pages/index.vue")
  },
  {
    name: "index___fi",
    path: "/fi/",
    component: () => import("/build/src/pages/index.vue")
  },
  {
    name: "index___da",
    path: "/da/",
    component: () => import("/build/src/pages/index.vue")
  },
  {
    name: "index___nl",
    path: "/nl/",
    component: () => import("/build/src/pages/index.vue")
  },
  {
    name: "index___no",
    path: "/no/",
    component: () => import("/build/src/pages/index.vue")
  },
  {
    name: "index___cs",
    path: "/cs/",
    component: () => import("/build/src/pages/index.vue")
  },
  {
    name: "index___ru",
    path: "/ru/",
    component: () => import("/build/src/pages/index.vue")
  },
  {
    name: "index___tr",
    path: "/tr/",
    component: () => import("/build/src/pages/index.vue")
  },
  {
    name: "index___zh",
    path: "/zh/",
    component: () => import("/build/src/pages/index.vue")
  },
  {
    name: "index___ja",
    path: "/ja/",
    component: () => import("/build/src/pages/index.vue")
  },
  {
    name: "index___ko",
    path: "/ko/",
    component: () => import("/build/src/pages/index.vue")
  },
  {
    name: "_debug-bf___en",
    path: "/_debug/bf/",
    meta: bfJpctSeIL9nIebTxIKAlxHMnAEuBJTNVMcUulZ_45AauyMMeta || {},
    component: () => import("/build/src/pages/_debug/bf.vue")
  },
  {
    name: "_debug-bf___fr",
    path: "/fr/_debug/bf/",
    meta: bfJpctSeIL9nIebTxIKAlxHMnAEuBJTNVMcUulZ_45AauyMMeta || {},
    component: () => import("/build/src/pages/_debug/bf.vue")
  },
  {
    name: "_debug-bf___de",
    path: "/de/_debug/bf/",
    meta: bfJpctSeIL9nIebTxIKAlxHMnAEuBJTNVMcUulZ_45AauyMMeta || {},
    component: () => import("/build/src/pages/_debug/bf.vue")
  },
  {
    name: "_debug-bf___es",
    path: "/es/_debug/bf/",
    meta: bfJpctSeIL9nIebTxIKAlxHMnAEuBJTNVMcUulZ_45AauyMMeta || {},
    component: () => import("/build/src/pages/_debug/bf.vue")
  },
  {
    name: "_debug-bf___it",
    path: "/it/_debug/bf/",
    meta: bfJpctSeIL9nIebTxIKAlxHMnAEuBJTNVMcUulZ_45AauyMMeta || {},
    component: () => import("/build/src/pages/_debug/bf.vue")
  },
  {
    name: "_debug-bf___pt",
    path: "/pt/_debug/bf/",
    meta: bfJpctSeIL9nIebTxIKAlxHMnAEuBJTNVMcUulZ_45AauyMMeta || {},
    component: () => import("/build/src/pages/_debug/bf.vue")
  },
  {
    name: "_debug-bf___br",
    path: "/br/_debug/bf/",
    meta: bfJpctSeIL9nIebTxIKAlxHMnAEuBJTNVMcUulZ_45AauyMMeta || {},
    component: () => import("/build/src/pages/_debug/bf.vue")
  },
  {
    name: "_debug-bf___sv",
    path: "/sv/_debug/bf/",
    meta: bfJpctSeIL9nIebTxIKAlxHMnAEuBJTNVMcUulZ_45AauyMMeta || {},
    component: () => import("/build/src/pages/_debug/bf.vue")
  },
  {
    name: "_debug-bf___pl",
    path: "/pl/_debug/bf/",
    meta: bfJpctSeIL9nIebTxIKAlxHMnAEuBJTNVMcUulZ_45AauyMMeta || {},
    component: () => import("/build/src/pages/_debug/bf.vue")
  },
  {
    name: "_debug-bf___fi",
    path: "/fi/_debug/bf/",
    meta: bfJpctSeIL9nIebTxIKAlxHMnAEuBJTNVMcUulZ_45AauyMMeta || {},
    component: () => import("/build/src/pages/_debug/bf.vue")
  },
  {
    name: "_debug-bf___da",
    path: "/da/_debug/bf/",
    meta: bfJpctSeIL9nIebTxIKAlxHMnAEuBJTNVMcUulZ_45AauyMMeta || {},
    component: () => import("/build/src/pages/_debug/bf.vue")
  },
  {
    name: "_debug-bf___nl",
    path: "/nl/_debug/bf/",
    meta: bfJpctSeIL9nIebTxIKAlxHMnAEuBJTNVMcUulZ_45AauyMMeta || {},
    component: () => import("/build/src/pages/_debug/bf.vue")
  },
  {
    name: "_debug-bf___no",
    path: "/no/_debug/bf/",
    meta: bfJpctSeIL9nIebTxIKAlxHMnAEuBJTNVMcUulZ_45AauyMMeta || {},
    component: () => import("/build/src/pages/_debug/bf.vue")
  },
  {
    name: "_debug-bf___cs",
    path: "/cs/_debug/bf/",
    meta: bfJpctSeIL9nIebTxIKAlxHMnAEuBJTNVMcUulZ_45AauyMMeta || {},
    component: () => import("/build/src/pages/_debug/bf.vue")
  },
  {
    name: "_debug-bf___ru",
    path: "/ru/_debug/bf/",
    meta: bfJpctSeIL9nIebTxIKAlxHMnAEuBJTNVMcUulZ_45AauyMMeta || {},
    component: () => import("/build/src/pages/_debug/bf.vue")
  },
  {
    name: "_debug-bf___tr",
    path: "/tr/_debug/bf/",
    meta: bfJpctSeIL9nIebTxIKAlxHMnAEuBJTNVMcUulZ_45AauyMMeta || {},
    component: () => import("/build/src/pages/_debug/bf.vue")
  },
  {
    name: "_debug-bf___zh",
    path: "/zh/_debug/bf/",
    meta: bfJpctSeIL9nIebTxIKAlxHMnAEuBJTNVMcUulZ_45AauyMMeta || {},
    component: () => import("/build/src/pages/_debug/bf.vue")
  },
  {
    name: "_debug-bf___ja",
    path: "/ja/_debug/bf/",
    meta: bfJpctSeIL9nIebTxIKAlxHMnAEuBJTNVMcUulZ_45AauyMMeta || {},
    component: () => import("/build/src/pages/_debug/bf.vue")
  },
  {
    name: "_debug-bf___ko",
    path: "/ko/_debug/bf/",
    meta: bfJpctSeIL9nIebTxIKAlxHMnAEuBJTNVMcUulZ_45AauyMMeta || {},
    component: () => import("/build/src/pages/_debug/bf.vue")
  },
  {
    name: "_debug___en",
    path: "/_debug/",
    meta: indexhWCKqreG_py_Q7PZ5kg5SUxqF3eHEesclmaLLx_IgogMeta || {},
    component: () => import("/build/src/pages/_debug/index.vue")
  },
  {
    name: "_debug___fr",
    path: "/fr/_debug/",
    meta: indexhWCKqreG_py_Q7PZ5kg5SUxqF3eHEesclmaLLx_IgogMeta || {},
    component: () => import("/build/src/pages/_debug/index.vue")
  },
  {
    name: "_debug___de",
    path: "/de/_debug/",
    meta: indexhWCKqreG_py_Q7PZ5kg5SUxqF3eHEesclmaLLx_IgogMeta || {},
    component: () => import("/build/src/pages/_debug/index.vue")
  },
  {
    name: "_debug___es",
    path: "/es/_debug/",
    meta: indexhWCKqreG_py_Q7PZ5kg5SUxqF3eHEesclmaLLx_IgogMeta || {},
    component: () => import("/build/src/pages/_debug/index.vue")
  },
  {
    name: "_debug___it",
    path: "/it/_debug/",
    meta: indexhWCKqreG_py_Q7PZ5kg5SUxqF3eHEesclmaLLx_IgogMeta || {},
    component: () => import("/build/src/pages/_debug/index.vue")
  },
  {
    name: "_debug___pt",
    path: "/pt/_debug/",
    meta: indexhWCKqreG_py_Q7PZ5kg5SUxqF3eHEesclmaLLx_IgogMeta || {},
    component: () => import("/build/src/pages/_debug/index.vue")
  },
  {
    name: "_debug___br",
    path: "/br/_debug/",
    meta: indexhWCKqreG_py_Q7PZ5kg5SUxqF3eHEesclmaLLx_IgogMeta || {},
    component: () => import("/build/src/pages/_debug/index.vue")
  },
  {
    name: "_debug___sv",
    path: "/sv/_debug/",
    meta: indexhWCKqreG_py_Q7PZ5kg5SUxqF3eHEesclmaLLx_IgogMeta || {},
    component: () => import("/build/src/pages/_debug/index.vue")
  },
  {
    name: "_debug___pl",
    path: "/pl/_debug/",
    meta: indexhWCKqreG_py_Q7PZ5kg5SUxqF3eHEesclmaLLx_IgogMeta || {},
    component: () => import("/build/src/pages/_debug/index.vue")
  },
  {
    name: "_debug___fi",
    path: "/fi/_debug/",
    meta: indexhWCKqreG_py_Q7PZ5kg5SUxqF3eHEesclmaLLx_IgogMeta || {},
    component: () => import("/build/src/pages/_debug/index.vue")
  },
  {
    name: "_debug___da",
    path: "/da/_debug/",
    meta: indexhWCKqreG_py_Q7PZ5kg5SUxqF3eHEesclmaLLx_IgogMeta || {},
    component: () => import("/build/src/pages/_debug/index.vue")
  },
  {
    name: "_debug___nl",
    path: "/nl/_debug/",
    meta: indexhWCKqreG_py_Q7PZ5kg5SUxqF3eHEesclmaLLx_IgogMeta || {},
    component: () => import("/build/src/pages/_debug/index.vue")
  },
  {
    name: "_debug___no",
    path: "/no/_debug/",
    meta: indexhWCKqreG_py_Q7PZ5kg5SUxqF3eHEesclmaLLx_IgogMeta || {},
    component: () => import("/build/src/pages/_debug/index.vue")
  },
  {
    name: "_debug___cs",
    path: "/cs/_debug/",
    meta: indexhWCKqreG_py_Q7PZ5kg5SUxqF3eHEesclmaLLx_IgogMeta || {},
    component: () => import("/build/src/pages/_debug/index.vue")
  },
  {
    name: "_debug___ru",
    path: "/ru/_debug/",
    meta: indexhWCKqreG_py_Q7PZ5kg5SUxqF3eHEesclmaLLx_IgogMeta || {},
    component: () => import("/build/src/pages/_debug/index.vue")
  },
  {
    name: "_debug___tr",
    path: "/tr/_debug/",
    meta: indexhWCKqreG_py_Q7PZ5kg5SUxqF3eHEesclmaLLx_IgogMeta || {},
    component: () => import("/build/src/pages/_debug/index.vue")
  },
  {
    name: "_debug___zh",
    path: "/zh/_debug/",
    meta: indexhWCKqreG_py_Q7PZ5kg5SUxqF3eHEesclmaLLx_IgogMeta || {},
    component: () => import("/build/src/pages/_debug/index.vue")
  },
  {
    name: "_debug___ja",
    path: "/ja/_debug/",
    meta: indexhWCKqreG_py_Q7PZ5kg5SUxqF3eHEesclmaLLx_IgogMeta || {},
    component: () => import("/build/src/pages/_debug/index.vue")
  },
  {
    name: "_debug___ko",
    path: "/ko/_debug/",
    meta: indexhWCKqreG_py_Q7PZ5kg5SUxqF3eHEesclmaLLx_IgogMeta || {},
    component: () => import("/build/src/pages/_debug/index.vue")
  },
  {
    name: "sitemap___en",
    path: "/sitemap/",
    component: () => import("/build/src/pages/sitemap/index.vue")
  },
  {
    name: "sitemap___fr",
    path: "/fr/sitemap/",
    component: () => import("/build/src/pages/sitemap/index.vue")
  },
  {
    name: "sitemap___de",
    path: "/de/sitemap/",
    component: () => import("/build/src/pages/sitemap/index.vue")
  },
  {
    name: "sitemap___es",
    path: "/es/sitemap/",
    component: () => import("/build/src/pages/sitemap/index.vue")
  },
  {
    name: "sitemap___it",
    path: "/it/sitemap/",
    component: () => import("/build/src/pages/sitemap/index.vue")
  },
  {
    name: "sitemap___pt",
    path: "/pt/sitemap/",
    component: () => import("/build/src/pages/sitemap/index.vue")
  },
  {
    name: "sitemap___br",
    path: "/br/sitemap/",
    component: () => import("/build/src/pages/sitemap/index.vue")
  },
  {
    name: "sitemap___sv",
    path: "/sv/sitemap/",
    component: () => import("/build/src/pages/sitemap/index.vue")
  },
  {
    name: "sitemap___pl",
    path: "/pl/sitemap/",
    component: () => import("/build/src/pages/sitemap/index.vue")
  },
  {
    name: "sitemap___fi",
    path: "/fi/sitemap/",
    component: () => import("/build/src/pages/sitemap/index.vue")
  },
  {
    name: "sitemap___da",
    path: "/da/sitemap/",
    component: () => import("/build/src/pages/sitemap/index.vue")
  },
  {
    name: "sitemap___nl",
    path: "/nl/sitemap/",
    component: () => import("/build/src/pages/sitemap/index.vue")
  },
  {
    name: "sitemap___no",
    path: "/no/sitemap/",
    component: () => import("/build/src/pages/sitemap/index.vue")
  },
  {
    name: "sitemap___cs",
    path: "/cs/sitemap/",
    component: () => import("/build/src/pages/sitemap/index.vue")
  },
  {
    name: "sitemap___ru",
    path: "/ru/sitemap/",
    component: () => import("/build/src/pages/sitemap/index.vue")
  },
  {
    name: "sitemap___tr",
    path: "/tr/sitemap/",
    component: () => import("/build/src/pages/sitemap/index.vue")
  },
  {
    name: "sitemap___zh",
    path: "/zh/sitemap/",
    component: () => import("/build/src/pages/sitemap/index.vue")
  },
  {
    name: "sitemap___ja",
    path: "/ja/sitemap/",
    component: () => import("/build/src/pages/sitemap/index.vue")
  },
  {
    name: "sitemap___ko",
    path: "/ko/sitemap/",
    component: () => import("/build/src/pages/sitemap/index.vue")
  },
  {
    name: "guarantee___en",
    path: "/guarantee/",
    component: () => import("/build/src/pages/guarantee/index.vue")
  },
  {
    name: "guarantee___fr",
    path: "/fr/guarantee/",
    component: () => import("/build/src/pages/guarantee/index.vue")
  },
  {
    name: "guarantee___de",
    path: "/de/guarantee/",
    component: () => import("/build/src/pages/guarantee/index.vue")
  },
  {
    name: "guarantee___es",
    path: "/es/guarantee/",
    component: () => import("/build/src/pages/guarantee/index.vue")
  },
  {
    name: "guarantee___it",
    path: "/it/guarantee/",
    component: () => import("/build/src/pages/guarantee/index.vue")
  },
  {
    name: "guarantee___pt",
    path: "/pt/guarantee/",
    component: () => import("/build/src/pages/guarantee/index.vue")
  },
  {
    name: "guarantee___br",
    path: "/br/guarantee/",
    component: () => import("/build/src/pages/guarantee/index.vue")
  },
  {
    name: "guarantee___sv",
    path: "/sv/guarantee/",
    component: () => import("/build/src/pages/guarantee/index.vue")
  },
  {
    name: "guarantee___pl",
    path: "/pl/guarantee/",
    component: () => import("/build/src/pages/guarantee/index.vue")
  },
  {
    name: "guarantee___fi",
    path: "/fi/guarantee/",
    component: () => import("/build/src/pages/guarantee/index.vue")
  },
  {
    name: "guarantee___da",
    path: "/da/guarantee/",
    component: () => import("/build/src/pages/guarantee/index.vue")
  },
  {
    name: "guarantee___nl",
    path: "/nl/guarantee/",
    component: () => import("/build/src/pages/guarantee/index.vue")
  },
  {
    name: "guarantee___no",
    path: "/no/guarantee/",
    component: () => import("/build/src/pages/guarantee/index.vue")
  },
  {
    name: "guarantee___cs",
    path: "/cs/guarantee/",
    component: () => import("/build/src/pages/guarantee/index.vue")
  },
  {
    name: "guarantee___ru",
    path: "/ru/guarantee/",
    component: () => import("/build/src/pages/guarantee/index.vue")
  },
  {
    name: "guarantee___tr",
    path: "/tr/guarantee/",
    component: () => import("/build/src/pages/guarantee/index.vue")
  },
  {
    name: "guarantee___zh",
    path: "/zh/guarantee/",
    component: () => import("/build/src/pages/guarantee/index.vue")
  },
  {
    name: "guarantee___ja",
    path: "/ja/guarantee/",
    component: () => import("/build/src/pages/guarantee/index.vue")
  },
  {
    name: "guarantee___ko",
    path: "/ko/guarantee/",
    component: () => import("/build/src/pages/guarantee/index.vue")
  },
  {
    name: "backpackers___en",
    path: "/backpackers/",
    component: () => import("/build/src/pages/backpackers/index.vue")
  },
  {
    name: "backpackers___fr",
    path: "/fr/backpackers/",
    component: () => import("/build/src/pages/backpackers/index.vue")
  },
  {
    name: "backpackers___de",
    path: "/de/backpackers/",
    component: () => import("/build/src/pages/backpackers/index.vue")
  },
  {
    name: "backpackers___es",
    path: "/es/backpackers/",
    component: () => import("/build/src/pages/backpackers/index.vue")
  },
  {
    name: "backpackers___it",
    path: "/it/backpackers/",
    component: () => import("/build/src/pages/backpackers/index.vue")
  },
  {
    name: "backpackers___pt",
    path: "/pt/backpackers/",
    component: () => import("/build/src/pages/backpackers/index.vue")
  },
  {
    name: "backpackers___br",
    path: "/br/backpackers/",
    component: () => import("/build/src/pages/backpackers/index.vue")
  },
  {
    name: "backpackers___sv",
    path: "/sv/backpackers/",
    component: () => import("/build/src/pages/backpackers/index.vue")
  },
  {
    name: "backpackers___pl",
    path: "/pl/backpackers/",
    component: () => import("/build/src/pages/backpackers/index.vue")
  },
  {
    name: "backpackers___fi",
    path: "/fi/backpackers/",
    component: () => import("/build/src/pages/backpackers/index.vue")
  },
  {
    name: "backpackers___da",
    path: "/da/backpackers/",
    component: () => import("/build/src/pages/backpackers/index.vue")
  },
  {
    name: "backpackers___nl",
    path: "/nl/backpackers/",
    component: () => import("/build/src/pages/backpackers/index.vue")
  },
  {
    name: "backpackers___no",
    path: "/no/backpackers/",
    component: () => import("/build/src/pages/backpackers/index.vue")
  },
  {
    name: "backpackers___cs",
    path: "/cs/backpackers/",
    component: () => import("/build/src/pages/backpackers/index.vue")
  },
  {
    name: "backpackers___ru",
    path: "/ru/backpackers/",
    component: () => import("/build/src/pages/backpackers/index.vue")
  },
  {
    name: "backpackers___tr",
    path: "/tr/backpackers/",
    component: () => import("/build/src/pages/backpackers/index.vue")
  },
  {
    name: "backpackers___zh",
    path: "/zh/backpackers/",
    component: () => import("/build/src/pages/backpackers/index.vue")
  },
  {
    name: "backpackers___ja",
    path: "/ja/backpackers/",
    component: () => import("/build/src/pages/backpackers/index.vue")
  },
  {
    name: "backpackers___ko",
    path: "/ko/backpackers/",
    component: () => import("/build/src/pages/backpackers/index.vue")
  },
  {
    name: "cookiespolicy___en",
    path: "/cookiespolicy/",
    component: () => import("/build/src/pages/cookiespolicy/index.vue")
  },
  {
    name: "cookiespolicy___fr",
    path: "/fr/cookiespolicy/",
    component: () => import("/build/src/pages/cookiespolicy/index.vue")
  },
  {
    name: "cookiespolicy___de",
    path: "/de/cookiespolicy/",
    component: () => import("/build/src/pages/cookiespolicy/index.vue")
  },
  {
    name: "cookiespolicy___es",
    path: "/es/cookiespolicy/",
    component: () => import("/build/src/pages/cookiespolicy/index.vue")
  },
  {
    name: "cookiespolicy___it",
    path: "/it/cookiespolicy/",
    component: () => import("/build/src/pages/cookiespolicy/index.vue")
  },
  {
    name: "cookiespolicy___pt",
    path: "/pt/cookiespolicy/",
    component: () => import("/build/src/pages/cookiespolicy/index.vue")
  },
  {
    name: "cookiespolicy___br",
    path: "/br/cookiespolicy/",
    component: () => import("/build/src/pages/cookiespolicy/index.vue")
  },
  {
    name: "cookiespolicy___sv",
    path: "/sv/cookiespolicy/",
    component: () => import("/build/src/pages/cookiespolicy/index.vue")
  },
  {
    name: "cookiespolicy___pl",
    path: "/pl/cookiespolicy/",
    component: () => import("/build/src/pages/cookiespolicy/index.vue")
  },
  {
    name: "cookiespolicy___fi",
    path: "/fi/cookiespolicy/",
    component: () => import("/build/src/pages/cookiespolicy/index.vue")
  },
  {
    name: "cookiespolicy___da",
    path: "/da/cookiespolicy/",
    component: () => import("/build/src/pages/cookiespolicy/index.vue")
  },
  {
    name: "cookiespolicy___nl",
    path: "/nl/cookiespolicy/",
    component: () => import("/build/src/pages/cookiespolicy/index.vue")
  },
  {
    name: "cookiespolicy___no",
    path: "/no/cookiespolicy/",
    component: () => import("/build/src/pages/cookiespolicy/index.vue")
  },
  {
    name: "cookiespolicy___cs",
    path: "/cs/cookiespolicy/",
    component: () => import("/build/src/pages/cookiespolicy/index.vue")
  },
  {
    name: "cookiespolicy___ru",
    path: "/ru/cookiespolicy/",
    component: () => import("/build/src/pages/cookiespolicy/index.vue")
  },
  {
    name: "cookiespolicy___tr",
    path: "/tr/cookiespolicy/",
    component: () => import("/build/src/pages/cookiespolicy/index.vue")
  },
  {
    name: "cookiespolicy___zh",
    path: "/zh/cookiespolicy/",
    component: () => import("/build/src/pages/cookiespolicy/index.vue")
  },
  {
    name: "cookiespolicy___ja",
    path: "/ja/cookiespolicy/",
    component: () => import("/build/src/pages/cookiespolicy/index.vue")
  },
  {
    name: "cookiespolicy___ko",
    path: "/ko/cookiespolicy/",
    component: () => import("/build/src/pages/cookiespolicy/index.vue")
  },
  {
    name: "propertyType___en",
    path: "/:propertyType()/",
    meta: indexT6362h3eQvKkSsZv_0ra_45bElo2iYJDNKahBBn5wPzg0Meta || {},
    component: () => import("/build/src/pages/[propertyType]/index.vue")
  },
  {
    name: "propertyType___fr",
    path: "/fr/:propertyType()/",
    meta: indexT6362h3eQvKkSsZv_0ra_45bElo2iYJDNKahBBn5wPzg0Meta || {},
    component: () => import("/build/src/pages/[propertyType]/index.vue")
  },
  {
    name: "propertyType___de",
    path: "/de/:propertyType()/",
    meta: indexT6362h3eQvKkSsZv_0ra_45bElo2iYJDNKahBBn5wPzg0Meta || {},
    component: () => import("/build/src/pages/[propertyType]/index.vue")
  },
  {
    name: "propertyType___es",
    path: "/es/:propertyType()/",
    meta: indexT6362h3eQvKkSsZv_0ra_45bElo2iYJDNKahBBn5wPzg0Meta || {},
    component: () => import("/build/src/pages/[propertyType]/index.vue")
  },
  {
    name: "propertyType___it",
    path: "/it/:propertyType()/",
    meta: indexT6362h3eQvKkSsZv_0ra_45bElo2iYJDNKahBBn5wPzg0Meta || {},
    component: () => import("/build/src/pages/[propertyType]/index.vue")
  },
  {
    name: "propertyType___pt",
    path: "/pt/:propertyType()/",
    meta: indexT6362h3eQvKkSsZv_0ra_45bElo2iYJDNKahBBn5wPzg0Meta || {},
    component: () => import("/build/src/pages/[propertyType]/index.vue")
  },
  {
    name: "propertyType___br",
    path: "/br/:propertyType()/",
    meta: indexT6362h3eQvKkSsZv_0ra_45bElo2iYJDNKahBBn5wPzg0Meta || {},
    component: () => import("/build/src/pages/[propertyType]/index.vue")
  },
  {
    name: "propertyType___sv",
    path: "/sv/:propertyType()/",
    meta: indexT6362h3eQvKkSsZv_0ra_45bElo2iYJDNKahBBn5wPzg0Meta || {},
    component: () => import("/build/src/pages/[propertyType]/index.vue")
  },
  {
    name: "propertyType___pl",
    path: "/pl/:propertyType()/",
    meta: indexT6362h3eQvKkSsZv_0ra_45bElo2iYJDNKahBBn5wPzg0Meta || {},
    component: () => import("/build/src/pages/[propertyType]/index.vue")
  },
  {
    name: "propertyType___fi",
    path: "/fi/:propertyType()/",
    meta: indexT6362h3eQvKkSsZv_0ra_45bElo2iYJDNKahBBn5wPzg0Meta || {},
    component: () => import("/build/src/pages/[propertyType]/index.vue")
  },
  {
    name: "propertyType___da",
    path: "/da/:propertyType()/",
    meta: indexT6362h3eQvKkSsZv_0ra_45bElo2iYJDNKahBBn5wPzg0Meta || {},
    component: () => import("/build/src/pages/[propertyType]/index.vue")
  },
  {
    name: "propertyType___nl",
    path: "/nl/:propertyType()/",
    meta: indexT6362h3eQvKkSsZv_0ra_45bElo2iYJDNKahBBn5wPzg0Meta || {},
    component: () => import("/build/src/pages/[propertyType]/index.vue")
  },
  {
    name: "propertyType___no",
    path: "/no/:propertyType()/",
    meta: indexT6362h3eQvKkSsZv_0ra_45bElo2iYJDNKahBBn5wPzg0Meta || {},
    component: () => import("/build/src/pages/[propertyType]/index.vue")
  },
  {
    name: "propertyType___cs",
    path: "/cs/:propertyType()/",
    meta: indexT6362h3eQvKkSsZv_0ra_45bElo2iYJDNKahBBn5wPzg0Meta || {},
    component: () => import("/build/src/pages/[propertyType]/index.vue")
  },
  {
    name: "propertyType___ru",
    path: "/ru/:propertyType()/",
    meta: indexT6362h3eQvKkSsZv_0ra_45bElo2iYJDNKahBBn5wPzg0Meta || {},
    component: () => import("/build/src/pages/[propertyType]/index.vue")
  },
  {
    name: "propertyType___tr",
    path: "/tr/:propertyType()/",
    meta: indexT6362h3eQvKkSsZv_0ra_45bElo2iYJDNKahBBn5wPzg0Meta || {},
    component: () => import("/build/src/pages/[propertyType]/index.vue")
  },
  {
    name: "propertyType___zh",
    path: "/zh/:propertyType()/",
    meta: indexT6362h3eQvKkSsZv_0ra_45bElo2iYJDNKahBBn5wPzg0Meta || {},
    component: () => import("/build/src/pages/[propertyType]/index.vue")
  },
  {
    name: "propertyType___ja",
    path: "/ja/:propertyType()/",
    meta: indexT6362h3eQvKkSsZv_0ra_45bElo2iYJDNKahBBn5wPzg0Meta || {},
    component: () => import("/build/src/pages/[propertyType]/index.vue")
  },
  {
    name: "propertyType___ko",
    path: "/ko/:propertyType()/",
    meta: indexT6362h3eQvKkSsZv_0ra_45bElo2iYJDNKahBBn5wPzg0Meta || {},
    component: () => import("/build/src/pages/[propertyType]/index.vue")
  },
  {
    name: "guarantee-terms___en",
    path: "/guarantee/terms/",
    component: () => import("/build/src/pages/guarantee/terms/index.vue")
  },
  {
    name: "guarantee-terms___fr",
    path: "/fr/guarantee/terms/",
    component: () => import("/build/src/pages/guarantee/terms/index.vue")
  },
  {
    name: "guarantee-terms___de",
    path: "/de/guarantee/terms/",
    component: () => import("/build/src/pages/guarantee/terms/index.vue")
  },
  {
    name: "guarantee-terms___es",
    path: "/es/guarantee/terms/",
    component: () => import("/build/src/pages/guarantee/terms/index.vue")
  },
  {
    name: "guarantee-terms___it",
    path: "/it/guarantee/terms/",
    component: () => import("/build/src/pages/guarantee/terms/index.vue")
  },
  {
    name: "guarantee-terms___pt",
    path: "/pt/guarantee/terms/",
    component: () => import("/build/src/pages/guarantee/terms/index.vue")
  },
  {
    name: "guarantee-terms___br",
    path: "/br/guarantee/terms/",
    component: () => import("/build/src/pages/guarantee/terms/index.vue")
  },
  {
    name: "guarantee-terms___sv",
    path: "/sv/guarantee/terms/",
    component: () => import("/build/src/pages/guarantee/terms/index.vue")
  },
  {
    name: "guarantee-terms___pl",
    path: "/pl/guarantee/terms/",
    component: () => import("/build/src/pages/guarantee/terms/index.vue")
  },
  {
    name: "guarantee-terms___fi",
    path: "/fi/guarantee/terms/",
    component: () => import("/build/src/pages/guarantee/terms/index.vue")
  },
  {
    name: "guarantee-terms___da",
    path: "/da/guarantee/terms/",
    component: () => import("/build/src/pages/guarantee/terms/index.vue")
  },
  {
    name: "guarantee-terms___nl",
    path: "/nl/guarantee/terms/",
    component: () => import("/build/src/pages/guarantee/terms/index.vue")
  },
  {
    name: "guarantee-terms___no",
    path: "/no/guarantee/terms/",
    component: () => import("/build/src/pages/guarantee/terms/index.vue")
  },
  {
    name: "guarantee-terms___cs",
    path: "/cs/guarantee/terms/",
    component: () => import("/build/src/pages/guarantee/terms/index.vue")
  },
  {
    name: "guarantee-terms___ru",
    path: "/ru/guarantee/terms/",
    component: () => import("/build/src/pages/guarantee/terms/index.vue")
  },
  {
    name: "guarantee-terms___tr",
    path: "/tr/guarantee/terms/",
    component: () => import("/build/src/pages/guarantee/terms/index.vue")
  },
  {
    name: "guarantee-terms___zh",
    path: "/zh/guarantee/terms/",
    component: () => import("/build/src/pages/guarantee/terms/index.vue")
  },
  {
    name: "guarantee-terms___ja",
    path: "/ja/guarantee/terms/",
    component: () => import("/build/src/pages/guarantee/terms/index.vue")
  },
  {
    name: "guarantee-terms___ko",
    path: "/ko/guarantee/terms/",
    component: () => import("/build/src/pages/guarantee/terms/index.vue")
  },
  {
    name: "securityprivacy___en",
    path: "/securityprivacy/",
    component: () => import("/build/src/pages/securityprivacy/index.vue")
  },
  {
    name: "securityprivacy___fr",
    path: "/fr/securityprivacy/",
    component: () => import("/build/src/pages/securityprivacy/index.vue")
  },
  {
    name: "securityprivacy___de",
    path: "/de/securityprivacy/",
    component: () => import("/build/src/pages/securityprivacy/index.vue")
  },
  {
    name: "securityprivacy___es",
    path: "/es/securityprivacy/",
    component: () => import("/build/src/pages/securityprivacy/index.vue")
  },
  {
    name: "securityprivacy___it",
    path: "/it/securityprivacy/",
    component: () => import("/build/src/pages/securityprivacy/index.vue")
  },
  {
    name: "securityprivacy___pt",
    path: "/pt/securityprivacy/",
    component: () => import("/build/src/pages/securityprivacy/index.vue")
  },
  {
    name: "securityprivacy___br",
    path: "/br/securityprivacy/",
    component: () => import("/build/src/pages/securityprivacy/index.vue")
  },
  {
    name: "securityprivacy___sv",
    path: "/sv/securityprivacy/",
    component: () => import("/build/src/pages/securityprivacy/index.vue")
  },
  {
    name: "securityprivacy___pl",
    path: "/pl/securityprivacy/",
    component: () => import("/build/src/pages/securityprivacy/index.vue")
  },
  {
    name: "securityprivacy___fi",
    path: "/fi/securityprivacy/",
    component: () => import("/build/src/pages/securityprivacy/index.vue")
  },
  {
    name: "securityprivacy___da",
    path: "/da/securityprivacy/",
    component: () => import("/build/src/pages/securityprivacy/index.vue")
  },
  {
    name: "securityprivacy___nl",
    path: "/nl/securityprivacy/",
    component: () => import("/build/src/pages/securityprivacy/index.vue")
  },
  {
    name: "securityprivacy___no",
    path: "/no/securityprivacy/",
    component: () => import("/build/src/pages/securityprivacy/index.vue")
  },
  {
    name: "securityprivacy___cs",
    path: "/cs/securityprivacy/",
    component: () => import("/build/src/pages/securityprivacy/index.vue")
  },
  {
    name: "securityprivacy___ru",
    path: "/ru/securityprivacy/",
    component: () => import("/build/src/pages/securityprivacy/index.vue")
  },
  {
    name: "securityprivacy___tr",
    path: "/tr/securityprivacy/",
    component: () => import("/build/src/pages/securityprivacy/index.vue")
  },
  {
    name: "securityprivacy___zh",
    path: "/zh/securityprivacy/",
    component: () => import("/build/src/pages/securityprivacy/index.vue")
  },
  {
    name: "securityprivacy___ja",
    path: "/ja/securityprivacy/",
    component: () => import("/build/src/pages/securityprivacy/index.vue")
  },
  {
    name: "securityprivacy___ko",
    path: "/ko/securityprivacy/",
    component: () => import("/build/src/pages/securityprivacy/index.vue")
  },
  {
    name: "website-testing___en",
    path: "/website-testing/",
    component: () => import("/build/src/pages/website-testing/index.vue")
  },
  {
    name: "website-testing___fr",
    path: "/fr/website-testing/",
    component: () => import("/build/src/pages/website-testing/index.vue")
  },
  {
    name: "website-testing___de",
    path: "/de/website-testing/",
    component: () => import("/build/src/pages/website-testing/index.vue")
  },
  {
    name: "website-testing___es",
    path: "/es/website-testing/",
    component: () => import("/build/src/pages/website-testing/index.vue")
  },
  {
    name: "website-testing___it",
    path: "/it/website-testing/",
    component: () => import("/build/src/pages/website-testing/index.vue")
  },
  {
    name: "website-testing___pt",
    path: "/pt/website-testing/",
    component: () => import("/build/src/pages/website-testing/index.vue")
  },
  {
    name: "website-testing___br",
    path: "/br/website-testing/",
    component: () => import("/build/src/pages/website-testing/index.vue")
  },
  {
    name: "website-testing___sv",
    path: "/sv/website-testing/",
    component: () => import("/build/src/pages/website-testing/index.vue")
  },
  {
    name: "website-testing___pl",
    path: "/pl/website-testing/",
    component: () => import("/build/src/pages/website-testing/index.vue")
  },
  {
    name: "website-testing___fi",
    path: "/fi/website-testing/",
    component: () => import("/build/src/pages/website-testing/index.vue")
  },
  {
    name: "website-testing___da",
    path: "/da/website-testing/",
    component: () => import("/build/src/pages/website-testing/index.vue")
  },
  {
    name: "website-testing___nl",
    path: "/nl/website-testing/",
    component: () => import("/build/src/pages/website-testing/index.vue")
  },
  {
    name: "website-testing___no",
    path: "/no/website-testing/",
    component: () => import("/build/src/pages/website-testing/index.vue")
  },
  {
    name: "website-testing___cs",
    path: "/cs/website-testing/",
    component: () => import("/build/src/pages/website-testing/index.vue")
  },
  {
    name: "website-testing___ru",
    path: "/ru/website-testing/",
    component: () => import("/build/src/pages/website-testing/index.vue")
  },
  {
    name: "website-testing___tr",
    path: "/tr/website-testing/",
    component: () => import("/build/src/pages/website-testing/index.vue")
  },
  {
    name: "website-testing___zh",
    path: "/zh/website-testing/",
    component: () => import("/build/src/pages/website-testing/index.vue")
  },
  {
    name: "website-testing___ja",
    path: "/ja/website-testing/",
    component: () => import("/build/src/pages/website-testing/index.vue")
  },
  {
    name: "website-testing___ko",
    path: "/ko/website-testing/",
    component: () => import("/build/src/pages/website-testing/index.vue")
  },
  {
    name: "reviewguidelines___en",
    path: "/reviewguidelines/",
    component: () => import("/build/src/pages/reviewguidelines/index.vue")
  },
  {
    name: "reviewguidelines___fr",
    path: "/fr/reviewguidelines/",
    component: () => import("/build/src/pages/reviewguidelines/index.vue")
  },
  {
    name: "reviewguidelines___de",
    path: "/de/reviewguidelines/",
    component: () => import("/build/src/pages/reviewguidelines/index.vue")
  },
  {
    name: "reviewguidelines___es",
    path: "/es/reviewguidelines/",
    component: () => import("/build/src/pages/reviewguidelines/index.vue")
  },
  {
    name: "reviewguidelines___it",
    path: "/it/reviewguidelines/",
    component: () => import("/build/src/pages/reviewguidelines/index.vue")
  },
  {
    name: "reviewguidelines___pt",
    path: "/pt/reviewguidelines/",
    component: () => import("/build/src/pages/reviewguidelines/index.vue")
  },
  {
    name: "reviewguidelines___br",
    path: "/br/reviewguidelines/",
    component: () => import("/build/src/pages/reviewguidelines/index.vue")
  },
  {
    name: "reviewguidelines___sv",
    path: "/sv/reviewguidelines/",
    component: () => import("/build/src/pages/reviewguidelines/index.vue")
  },
  {
    name: "reviewguidelines___pl",
    path: "/pl/reviewguidelines/",
    component: () => import("/build/src/pages/reviewguidelines/index.vue")
  },
  {
    name: "reviewguidelines___fi",
    path: "/fi/reviewguidelines/",
    component: () => import("/build/src/pages/reviewguidelines/index.vue")
  },
  {
    name: "reviewguidelines___da",
    path: "/da/reviewguidelines/",
    component: () => import("/build/src/pages/reviewguidelines/index.vue")
  },
  {
    name: "reviewguidelines___nl",
    path: "/nl/reviewguidelines/",
    component: () => import("/build/src/pages/reviewguidelines/index.vue")
  },
  {
    name: "reviewguidelines___no",
    path: "/no/reviewguidelines/",
    component: () => import("/build/src/pages/reviewguidelines/index.vue")
  },
  {
    name: "reviewguidelines___cs",
    path: "/cs/reviewguidelines/",
    component: () => import("/build/src/pages/reviewguidelines/index.vue")
  },
  {
    name: "reviewguidelines___ru",
    path: "/ru/reviewguidelines/",
    component: () => import("/build/src/pages/reviewguidelines/index.vue")
  },
  {
    name: "reviewguidelines___tr",
    path: "/tr/reviewguidelines/",
    component: () => import("/build/src/pages/reviewguidelines/index.vue")
  },
  {
    name: "reviewguidelines___zh",
    path: "/zh/reviewguidelines/",
    component: () => import("/build/src/pages/reviewguidelines/index.vue")
  },
  {
    name: "reviewguidelines___ja",
    path: "/ja/reviewguidelines/",
    component: () => import("/build/src/pages/reviewguidelines/index.vue")
  },
  {
    name: "reviewguidelines___ko",
    path: "/ko/reviewguidelines/",
    component: () => import("/build/src/pages/reviewguidelines/index.vue")
  },
  {
    name: "legal-legalPage___en",
    path: "/legal/:legalPage()/",
    component: () => import("/build/src/pages/legal/[legalPage]/index.vue")
  },
  {
    name: "legal-legalPage___fr",
    path: "/fr/legal/:legalPage()/",
    component: () => import("/build/src/pages/legal/[legalPage]/index.vue")
  },
  {
    name: "legal-legalPage___de",
    path: "/de/legal/:legalPage()/",
    component: () => import("/build/src/pages/legal/[legalPage]/index.vue")
  },
  {
    name: "legal-legalPage___es",
    path: "/es/legal/:legalPage()/",
    component: () => import("/build/src/pages/legal/[legalPage]/index.vue")
  },
  {
    name: "legal-legalPage___it",
    path: "/it/legal/:legalPage()/",
    component: () => import("/build/src/pages/legal/[legalPage]/index.vue")
  },
  {
    name: "legal-legalPage___pt",
    path: "/pt/legal/:legalPage()/",
    component: () => import("/build/src/pages/legal/[legalPage]/index.vue")
  },
  {
    name: "legal-legalPage___br",
    path: "/br/legal/:legalPage()/",
    component: () => import("/build/src/pages/legal/[legalPage]/index.vue")
  },
  {
    name: "legal-legalPage___sv",
    path: "/sv/legal/:legalPage()/",
    component: () => import("/build/src/pages/legal/[legalPage]/index.vue")
  },
  {
    name: "legal-legalPage___pl",
    path: "/pl/legal/:legalPage()/",
    component: () => import("/build/src/pages/legal/[legalPage]/index.vue")
  },
  {
    name: "legal-legalPage___fi",
    path: "/fi/legal/:legalPage()/",
    component: () => import("/build/src/pages/legal/[legalPage]/index.vue")
  },
  {
    name: "legal-legalPage___da",
    path: "/da/legal/:legalPage()/",
    component: () => import("/build/src/pages/legal/[legalPage]/index.vue")
  },
  {
    name: "legal-legalPage___nl",
    path: "/nl/legal/:legalPage()/",
    component: () => import("/build/src/pages/legal/[legalPage]/index.vue")
  },
  {
    name: "legal-legalPage___no",
    path: "/no/legal/:legalPage()/",
    component: () => import("/build/src/pages/legal/[legalPage]/index.vue")
  },
  {
    name: "legal-legalPage___cs",
    path: "/cs/legal/:legalPage()/",
    component: () => import("/build/src/pages/legal/[legalPage]/index.vue")
  },
  {
    name: "legal-legalPage___ru",
    path: "/ru/legal/:legalPage()/",
    component: () => import("/build/src/pages/legal/[legalPage]/index.vue")
  },
  {
    name: "legal-legalPage___tr",
    path: "/tr/legal/:legalPage()/",
    component: () => import("/build/src/pages/legal/[legalPage]/index.vue")
  },
  {
    name: "legal-legalPage___zh",
    path: "/zh/legal/:legalPage()/",
    component: () => import("/build/src/pages/legal/[legalPage]/index.vue")
  },
  {
    name: "legal-legalPage___ja",
    path: "/ja/legal/:legalPage()/",
    component: () => import("/build/src/pages/legal/[legalPage]/index.vue")
  },
  {
    name: "legal-legalPage___ko",
    path: "/ko/legal/:legalPage()/",
    component: () => import("/build/src/pages/legal/[legalPage]/index.vue")
  },
  {
    name: "bestpriceguarantee___en",
    path: "/bestpriceguarantee/",
    component: () => import("/build/src/pages/bestpriceguarantee/index.vue")
  },
  {
    name: "bestpriceguarantee___fr",
    path: "/fr/bestpriceguarantee/",
    component: () => import("/build/src/pages/bestpriceguarantee/index.vue")
  },
  {
    name: "bestpriceguarantee___de",
    path: "/de/bestpriceguarantee/",
    component: () => import("/build/src/pages/bestpriceguarantee/index.vue")
  },
  {
    name: "bestpriceguarantee___es",
    path: "/es/bestpriceguarantee/",
    component: () => import("/build/src/pages/bestpriceguarantee/index.vue")
  },
  {
    name: "bestpriceguarantee___it",
    path: "/it/bestpriceguarantee/",
    component: () => import("/build/src/pages/bestpriceguarantee/index.vue")
  },
  {
    name: "bestpriceguarantee___pt",
    path: "/pt/bestpriceguarantee/",
    component: () => import("/build/src/pages/bestpriceguarantee/index.vue")
  },
  {
    name: "bestpriceguarantee___br",
    path: "/br/bestpriceguarantee/",
    component: () => import("/build/src/pages/bestpriceguarantee/index.vue")
  },
  {
    name: "bestpriceguarantee___sv",
    path: "/sv/bestpriceguarantee/",
    component: () => import("/build/src/pages/bestpriceguarantee/index.vue")
  },
  {
    name: "bestpriceguarantee___pl",
    path: "/pl/bestpriceguarantee/",
    component: () => import("/build/src/pages/bestpriceguarantee/index.vue")
  },
  {
    name: "bestpriceguarantee___fi",
    path: "/fi/bestpriceguarantee/",
    component: () => import("/build/src/pages/bestpriceguarantee/index.vue")
  },
  {
    name: "bestpriceguarantee___da",
    path: "/da/bestpriceguarantee/",
    component: () => import("/build/src/pages/bestpriceguarantee/index.vue")
  },
  {
    name: "bestpriceguarantee___nl",
    path: "/nl/bestpriceguarantee/",
    component: () => import("/build/src/pages/bestpriceguarantee/index.vue")
  },
  {
    name: "bestpriceguarantee___no",
    path: "/no/bestpriceguarantee/",
    component: () => import("/build/src/pages/bestpriceguarantee/index.vue")
  },
  {
    name: "bestpriceguarantee___cs",
    path: "/cs/bestpriceguarantee/",
    component: () => import("/build/src/pages/bestpriceguarantee/index.vue")
  },
  {
    name: "bestpriceguarantee___ru",
    path: "/ru/bestpriceguarantee/",
    component: () => import("/build/src/pages/bestpriceguarantee/index.vue")
  },
  {
    name: "bestpriceguarantee___tr",
    path: "/tr/bestpriceguarantee/",
    component: () => import("/build/src/pages/bestpriceguarantee/index.vue")
  },
  {
    name: "bestpriceguarantee___zh",
    path: "/zh/bestpriceguarantee/",
    component: () => import("/build/src/pages/bestpriceguarantee/index.vue")
  },
  {
    name: "bestpriceguarantee___ja",
    path: "/ja/bestpriceguarantee/",
    component: () => import("/build/src/pages/bestpriceguarantee/index.vue")
  },
  {
    name: "bestpriceguarantee___ko",
    path: "/ko/bestpriceguarantee/",
    component: () => import("/build/src/pages/bestpriceguarantee/index.vue")
  },
  {
    name: "deals-black-friday___en",
    path: "/deals/black-friday/",
    meta: indexXhO9ncyhb1Y05cld5fCKtblUyOldYGRLQA_HN9ukNmkMeta || {},
    component: () => import("/build/src/pages/deals/black-friday/index.vue")
  },
  {
    name: "deals-black-friday___fr",
    path: "/fr/deals/black-friday/",
    meta: indexXhO9ncyhb1Y05cld5fCKtblUyOldYGRLQA_HN9ukNmkMeta || {},
    component: () => import("/build/src/pages/deals/black-friday/index.vue")
  },
  {
    name: "deals-black-friday___de",
    path: "/de/deals/black-friday/",
    meta: indexXhO9ncyhb1Y05cld5fCKtblUyOldYGRLQA_HN9ukNmkMeta || {},
    component: () => import("/build/src/pages/deals/black-friday/index.vue")
  },
  {
    name: "deals-black-friday___es",
    path: "/es/deals/black-friday/",
    meta: indexXhO9ncyhb1Y05cld5fCKtblUyOldYGRLQA_HN9ukNmkMeta || {},
    component: () => import("/build/src/pages/deals/black-friday/index.vue")
  },
  {
    name: "deals-black-friday___it",
    path: "/it/deals/black-friday/",
    meta: indexXhO9ncyhb1Y05cld5fCKtblUyOldYGRLQA_HN9ukNmkMeta || {},
    component: () => import("/build/src/pages/deals/black-friday/index.vue")
  },
  {
    name: "deals-black-friday___pt",
    path: "/pt/deals/black-friday/",
    meta: indexXhO9ncyhb1Y05cld5fCKtblUyOldYGRLQA_HN9ukNmkMeta || {},
    component: () => import("/build/src/pages/deals/black-friday/index.vue")
  },
  {
    name: "deals-black-friday___br",
    path: "/br/deals/black-friday/",
    meta: indexXhO9ncyhb1Y05cld5fCKtblUyOldYGRLQA_HN9ukNmkMeta || {},
    component: () => import("/build/src/pages/deals/black-friday/index.vue")
  },
  {
    name: "deals-black-friday___sv",
    path: "/sv/deals/black-friday/",
    meta: indexXhO9ncyhb1Y05cld5fCKtblUyOldYGRLQA_HN9ukNmkMeta || {},
    component: () => import("/build/src/pages/deals/black-friday/index.vue")
  },
  {
    name: "deals-black-friday___pl",
    path: "/pl/deals/black-friday/",
    meta: indexXhO9ncyhb1Y05cld5fCKtblUyOldYGRLQA_HN9ukNmkMeta || {},
    component: () => import("/build/src/pages/deals/black-friday/index.vue")
  },
  {
    name: "deals-black-friday___fi",
    path: "/fi/deals/black-friday/",
    meta: indexXhO9ncyhb1Y05cld5fCKtblUyOldYGRLQA_HN9ukNmkMeta || {},
    component: () => import("/build/src/pages/deals/black-friday/index.vue")
  },
  {
    name: "deals-black-friday___da",
    path: "/da/deals/black-friday/",
    meta: indexXhO9ncyhb1Y05cld5fCKtblUyOldYGRLQA_HN9ukNmkMeta || {},
    component: () => import("/build/src/pages/deals/black-friday/index.vue")
  },
  {
    name: "deals-black-friday___nl",
    path: "/nl/deals/black-friday/",
    meta: indexXhO9ncyhb1Y05cld5fCKtblUyOldYGRLQA_HN9ukNmkMeta || {},
    component: () => import("/build/src/pages/deals/black-friday/index.vue")
  },
  {
    name: "deals-black-friday___no",
    path: "/no/deals/black-friday/",
    meta: indexXhO9ncyhb1Y05cld5fCKtblUyOldYGRLQA_HN9ukNmkMeta || {},
    component: () => import("/build/src/pages/deals/black-friday/index.vue")
  },
  {
    name: "deals-black-friday___cs",
    path: "/cs/deals/black-friday/",
    meta: indexXhO9ncyhb1Y05cld5fCKtblUyOldYGRLQA_HN9ukNmkMeta || {},
    component: () => import("/build/src/pages/deals/black-friday/index.vue")
  },
  {
    name: "deals-black-friday___ru",
    path: "/ru/deals/black-friday/",
    meta: indexXhO9ncyhb1Y05cld5fCKtblUyOldYGRLQA_HN9ukNmkMeta || {},
    component: () => import("/build/src/pages/deals/black-friday/index.vue")
  },
  {
    name: "deals-black-friday___tr",
    path: "/tr/deals/black-friday/",
    meta: indexXhO9ncyhb1Y05cld5fCKtblUyOldYGRLQA_HN9ukNmkMeta || {},
    component: () => import("/build/src/pages/deals/black-friday/index.vue")
  },
  {
    name: "deals-black-friday___zh",
    path: "/zh/deals/black-friday/",
    meta: indexXhO9ncyhb1Y05cld5fCKtblUyOldYGRLQA_HN9ukNmkMeta || {},
    component: () => import("/build/src/pages/deals/black-friday/index.vue")
  },
  {
    name: "deals-black-friday___ja",
    path: "/ja/deals/black-friday/",
    meta: indexXhO9ncyhb1Y05cld5fCKtblUyOldYGRLQA_HN9ukNmkMeta || {},
    component: () => import("/build/src/pages/deals/black-friday/index.vue")
  },
  {
    name: "deals-black-friday___ko",
    path: "/ko/deals/black-friday/",
    meta: indexXhO9ncyhb1Y05cld5fCKtblUyOldYGRLQA_HN9ukNmkMeta || {},
    component: () => import("/build/src/pages/deals/black-friday/index.vue")
  },
  {
    name: "hostel-group-brand___en",
    path: "/hostel-group/:brand()/",
    component: () => import("/build/src/pages/hostel-group/[brand]/index.vue")
  },
  {
    name: "hostel-group-brand___fr",
    path: "/fr/hostel-group/:brand()/",
    component: () => import("/build/src/pages/hostel-group/[brand]/index.vue")
  },
  {
    name: "hostel-group-brand___de",
    path: "/de/hostel-group/:brand()/",
    component: () => import("/build/src/pages/hostel-group/[brand]/index.vue")
  },
  {
    name: "hostel-group-brand___es",
    path: "/es/hostel-group/:brand()/",
    component: () => import("/build/src/pages/hostel-group/[brand]/index.vue")
  },
  {
    name: "hostel-group-brand___it",
    path: "/it/hostel-group/:brand()/",
    component: () => import("/build/src/pages/hostel-group/[brand]/index.vue")
  },
  {
    name: "hostel-group-brand___pt",
    path: "/pt/hostel-group/:brand()/",
    component: () => import("/build/src/pages/hostel-group/[brand]/index.vue")
  },
  {
    name: "hostel-group-brand___br",
    path: "/br/hostel-group/:brand()/",
    component: () => import("/build/src/pages/hostel-group/[brand]/index.vue")
  },
  {
    name: "hostel-group-brand___sv",
    path: "/sv/hostel-group/:brand()/",
    component: () => import("/build/src/pages/hostel-group/[brand]/index.vue")
  },
  {
    name: "hostel-group-brand___pl",
    path: "/pl/hostel-group/:brand()/",
    component: () => import("/build/src/pages/hostel-group/[brand]/index.vue")
  },
  {
    name: "hostel-group-brand___fi",
    path: "/fi/hostel-group/:brand()/",
    component: () => import("/build/src/pages/hostel-group/[brand]/index.vue")
  },
  {
    name: "hostel-group-brand___da",
    path: "/da/hostel-group/:brand()/",
    component: () => import("/build/src/pages/hostel-group/[brand]/index.vue")
  },
  {
    name: "hostel-group-brand___nl",
    path: "/nl/hostel-group/:brand()/",
    component: () => import("/build/src/pages/hostel-group/[brand]/index.vue")
  },
  {
    name: "hostel-group-brand___no",
    path: "/no/hostel-group/:brand()/",
    component: () => import("/build/src/pages/hostel-group/[brand]/index.vue")
  },
  {
    name: "hostel-group-brand___cs",
    path: "/cs/hostel-group/:brand()/",
    component: () => import("/build/src/pages/hostel-group/[brand]/index.vue")
  },
  {
    name: "hostel-group-brand___ru",
    path: "/ru/hostel-group/:brand()/",
    component: () => import("/build/src/pages/hostel-group/[brand]/index.vue")
  },
  {
    name: "hostel-group-brand___tr",
    path: "/tr/hostel-group/:brand()/",
    component: () => import("/build/src/pages/hostel-group/[brand]/index.vue")
  },
  {
    name: "hostel-group-brand___zh",
    path: "/zh/hostel-group/:brand()/",
    component: () => import("/build/src/pages/hostel-group/[brand]/index.vue")
  },
  {
    name: "hostel-group-brand___ja",
    path: "/ja/hostel-group/:brand()/",
    component: () => import("/build/src/pages/hostel-group/[brand]/index.vue")
  },
  {
    name: "hostel-group-brand___ko",
    path: "/ko/hostel-group/:brand()/",
    component: () => import("/build/src/pages/hostel-group/[brand]/index.vue")
  },
  {
    name: "coupons-terms-conditions___en",
    path: "/coupons-terms-conditions/",
    component: () => import("/build/src/pages/coupons-terms-conditions/index.vue")
  },
  {
    name: "coupons-terms-conditions___fr",
    path: "/fr/coupons-terms-conditions/",
    component: () => import("/build/src/pages/coupons-terms-conditions/index.vue")
  },
  {
    name: "coupons-terms-conditions___de",
    path: "/de/coupons-terms-conditions/",
    component: () => import("/build/src/pages/coupons-terms-conditions/index.vue")
  },
  {
    name: "coupons-terms-conditions___es",
    path: "/es/coupons-terms-conditions/",
    component: () => import("/build/src/pages/coupons-terms-conditions/index.vue")
  },
  {
    name: "coupons-terms-conditions___it",
    path: "/it/coupons-terms-conditions/",
    component: () => import("/build/src/pages/coupons-terms-conditions/index.vue")
  },
  {
    name: "coupons-terms-conditions___pt",
    path: "/pt/coupons-terms-conditions/",
    component: () => import("/build/src/pages/coupons-terms-conditions/index.vue")
  },
  {
    name: "coupons-terms-conditions___br",
    path: "/br/coupons-terms-conditions/",
    component: () => import("/build/src/pages/coupons-terms-conditions/index.vue")
  },
  {
    name: "coupons-terms-conditions___sv",
    path: "/sv/coupons-terms-conditions/",
    component: () => import("/build/src/pages/coupons-terms-conditions/index.vue")
  },
  {
    name: "coupons-terms-conditions___pl",
    path: "/pl/coupons-terms-conditions/",
    component: () => import("/build/src/pages/coupons-terms-conditions/index.vue")
  },
  {
    name: "coupons-terms-conditions___fi",
    path: "/fi/coupons-terms-conditions/",
    component: () => import("/build/src/pages/coupons-terms-conditions/index.vue")
  },
  {
    name: "coupons-terms-conditions___da",
    path: "/da/coupons-terms-conditions/",
    component: () => import("/build/src/pages/coupons-terms-conditions/index.vue")
  },
  {
    name: "coupons-terms-conditions___nl",
    path: "/nl/coupons-terms-conditions/",
    component: () => import("/build/src/pages/coupons-terms-conditions/index.vue")
  },
  {
    name: "coupons-terms-conditions___no",
    path: "/no/coupons-terms-conditions/",
    component: () => import("/build/src/pages/coupons-terms-conditions/index.vue")
  },
  {
    name: "coupons-terms-conditions___cs",
    path: "/cs/coupons-terms-conditions/",
    component: () => import("/build/src/pages/coupons-terms-conditions/index.vue")
  },
  {
    name: "coupons-terms-conditions___ru",
    path: "/ru/coupons-terms-conditions/",
    component: () => import("/build/src/pages/coupons-terms-conditions/index.vue")
  },
  {
    name: "coupons-terms-conditions___tr",
    path: "/tr/coupons-terms-conditions/",
    component: () => import("/build/src/pages/coupons-terms-conditions/index.vue")
  },
  {
    name: "coupons-terms-conditions___zh",
    path: "/zh/coupons-terms-conditions/",
    component: () => import("/build/src/pages/coupons-terms-conditions/index.vue")
  },
  {
    name: "coupons-terms-conditions___ja",
    path: "/ja/coupons-terms-conditions/",
    component: () => import("/build/src/pages/coupons-terms-conditions/index.vue")
  },
  {
    name: "coupons-terms-conditions___ko",
    path: "/ko/coupons-terms-conditions/",
    component: () => import("/build/src/pages/coupons-terms-conditions/index.vue")
  },
  {
    name: "hosteltermsandconditions___en",
    path: "/hosteltermsandconditions/",
    component: () => import("/build/src/pages/hosteltermsandconditions/index.vue")
  },
  {
    name: "hosteltermsandconditions___fr",
    path: "/fr/hosteltermsandconditions/",
    component: () => import("/build/src/pages/hosteltermsandconditions/index.vue")
  },
  {
    name: "hosteltermsandconditions___de",
    path: "/de/hosteltermsandconditions/",
    component: () => import("/build/src/pages/hosteltermsandconditions/index.vue")
  },
  {
    name: "hosteltermsandconditions___es",
    path: "/es/hosteltermsandconditions/",
    component: () => import("/build/src/pages/hosteltermsandconditions/index.vue")
  },
  {
    name: "hosteltermsandconditions___it",
    path: "/it/hosteltermsandconditions/",
    component: () => import("/build/src/pages/hosteltermsandconditions/index.vue")
  },
  {
    name: "hosteltermsandconditions___pt",
    path: "/pt/hosteltermsandconditions/",
    component: () => import("/build/src/pages/hosteltermsandconditions/index.vue")
  },
  {
    name: "hosteltermsandconditions___br",
    path: "/br/hosteltermsandconditions/",
    component: () => import("/build/src/pages/hosteltermsandconditions/index.vue")
  },
  {
    name: "hosteltermsandconditions___sv",
    path: "/sv/hosteltermsandconditions/",
    component: () => import("/build/src/pages/hosteltermsandconditions/index.vue")
  },
  {
    name: "hosteltermsandconditions___pl",
    path: "/pl/hosteltermsandconditions/",
    component: () => import("/build/src/pages/hosteltermsandconditions/index.vue")
  },
  {
    name: "hosteltermsandconditions___fi",
    path: "/fi/hosteltermsandconditions/",
    component: () => import("/build/src/pages/hosteltermsandconditions/index.vue")
  },
  {
    name: "hosteltermsandconditions___da",
    path: "/da/hosteltermsandconditions/",
    component: () => import("/build/src/pages/hosteltermsandconditions/index.vue")
  },
  {
    name: "hosteltermsandconditions___nl",
    path: "/nl/hosteltermsandconditions/",
    component: () => import("/build/src/pages/hosteltermsandconditions/index.vue")
  },
  {
    name: "hosteltermsandconditions___no",
    path: "/no/hosteltermsandconditions/",
    component: () => import("/build/src/pages/hosteltermsandconditions/index.vue")
  },
  {
    name: "hosteltermsandconditions___cs",
    path: "/cs/hosteltermsandconditions/",
    component: () => import("/build/src/pages/hosteltermsandconditions/index.vue")
  },
  {
    name: "hosteltermsandconditions___ru",
    path: "/ru/hosteltermsandconditions/",
    component: () => import("/build/src/pages/hosteltermsandconditions/index.vue")
  },
  {
    name: "hosteltermsandconditions___tr",
    path: "/tr/hosteltermsandconditions/",
    component: () => import("/build/src/pages/hosteltermsandconditions/index.vue")
  },
  {
    name: "hosteltermsandconditions___zh",
    path: "/zh/hosteltermsandconditions/",
    component: () => import("/build/src/pages/hosteltermsandconditions/index.vue")
  },
  {
    name: "hosteltermsandconditions___ja",
    path: "/ja/hosteltermsandconditions/",
    component: () => import("/build/src/pages/hosteltermsandconditions/index.vue")
  },
  {
    name: "hosteltermsandconditions___ko",
    path: "/ko/hosteltermsandconditions/",
    component: () => import("/build/src/pages/hosteltermsandconditions/index.vue")
  },
  {
    name: "vouchertermsandconditions___en",
    path: "/vouchertermsandconditions/",
    component: () => import("/build/src/pages/vouchertermsandconditions/index.vue")
  },
  {
    name: "vouchertermsandconditions___fr",
    path: "/fr/vouchertermsandconditions/",
    component: () => import("/build/src/pages/vouchertermsandconditions/index.vue")
  },
  {
    name: "vouchertermsandconditions___de",
    path: "/de/vouchertermsandconditions/",
    component: () => import("/build/src/pages/vouchertermsandconditions/index.vue")
  },
  {
    name: "vouchertermsandconditions___es",
    path: "/es/vouchertermsandconditions/",
    component: () => import("/build/src/pages/vouchertermsandconditions/index.vue")
  },
  {
    name: "vouchertermsandconditions___it",
    path: "/it/vouchertermsandconditions/",
    component: () => import("/build/src/pages/vouchertermsandconditions/index.vue")
  },
  {
    name: "vouchertermsandconditions___pt",
    path: "/pt/vouchertermsandconditions/",
    component: () => import("/build/src/pages/vouchertermsandconditions/index.vue")
  },
  {
    name: "vouchertermsandconditions___br",
    path: "/br/vouchertermsandconditions/",
    component: () => import("/build/src/pages/vouchertermsandconditions/index.vue")
  },
  {
    name: "vouchertermsandconditions___sv",
    path: "/sv/vouchertermsandconditions/",
    component: () => import("/build/src/pages/vouchertermsandconditions/index.vue")
  },
  {
    name: "vouchertermsandconditions___pl",
    path: "/pl/vouchertermsandconditions/",
    component: () => import("/build/src/pages/vouchertermsandconditions/index.vue")
  },
  {
    name: "vouchertermsandconditions___fi",
    path: "/fi/vouchertermsandconditions/",
    component: () => import("/build/src/pages/vouchertermsandconditions/index.vue")
  },
  {
    name: "vouchertermsandconditions___da",
    path: "/da/vouchertermsandconditions/",
    component: () => import("/build/src/pages/vouchertermsandconditions/index.vue")
  },
  {
    name: "vouchertermsandconditions___nl",
    path: "/nl/vouchertermsandconditions/",
    component: () => import("/build/src/pages/vouchertermsandconditions/index.vue")
  },
  {
    name: "vouchertermsandconditions___no",
    path: "/no/vouchertermsandconditions/",
    component: () => import("/build/src/pages/vouchertermsandconditions/index.vue")
  },
  {
    name: "vouchertermsandconditions___cs",
    path: "/cs/vouchertermsandconditions/",
    component: () => import("/build/src/pages/vouchertermsandconditions/index.vue")
  },
  {
    name: "vouchertermsandconditions___ru",
    path: "/ru/vouchertermsandconditions/",
    component: () => import("/build/src/pages/vouchertermsandconditions/index.vue")
  },
  {
    name: "vouchertermsandconditions___tr",
    path: "/tr/vouchertermsandconditions/",
    component: () => import("/build/src/pages/vouchertermsandconditions/index.vue")
  },
  {
    name: "vouchertermsandconditions___zh",
    path: "/zh/vouchertermsandconditions/",
    component: () => import("/build/src/pages/vouchertermsandconditions/index.vue")
  },
  {
    name: "vouchertermsandconditions___ja",
    path: "/ja/vouchertermsandconditions/",
    component: () => import("/build/src/pages/vouchertermsandconditions/index.vue")
  },
  {
    name: "vouchertermsandconditions___ko",
    path: "/ko/vouchertermsandconditions/",
    component: () => import("/build/src/pages/vouchertermsandconditions/index.vue")
  },
  {
    name: "propertyType-continent___en",
    path: "/:propertyType()/:continent()/",
    meta: indexzqzss_45u7_45jPSVMfSvVZ0_45hobutEpyDTj7bOZCV_gsu0Meta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/index.vue")
  },
  {
    name: "propertyType-continent___fr",
    path: "/fr/:propertyType()/:continent()/",
    meta: indexzqzss_45u7_45jPSVMfSvVZ0_45hobutEpyDTj7bOZCV_gsu0Meta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/index.vue")
  },
  {
    name: "propertyType-continent___de",
    path: "/de/:propertyType()/:continent()/",
    meta: indexzqzss_45u7_45jPSVMfSvVZ0_45hobutEpyDTj7bOZCV_gsu0Meta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/index.vue")
  },
  {
    name: "propertyType-continent___es",
    path: "/es/:propertyType()/:continent()/",
    meta: indexzqzss_45u7_45jPSVMfSvVZ0_45hobutEpyDTj7bOZCV_gsu0Meta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/index.vue")
  },
  {
    name: "propertyType-continent___it",
    path: "/it/:propertyType()/:continent()/",
    meta: indexzqzss_45u7_45jPSVMfSvVZ0_45hobutEpyDTj7bOZCV_gsu0Meta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/index.vue")
  },
  {
    name: "propertyType-continent___pt",
    path: "/pt/:propertyType()/:continent()/",
    meta: indexzqzss_45u7_45jPSVMfSvVZ0_45hobutEpyDTj7bOZCV_gsu0Meta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/index.vue")
  },
  {
    name: "propertyType-continent___br",
    path: "/br/:propertyType()/:continent()/",
    meta: indexzqzss_45u7_45jPSVMfSvVZ0_45hobutEpyDTj7bOZCV_gsu0Meta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/index.vue")
  },
  {
    name: "propertyType-continent___sv",
    path: "/sv/:propertyType()/:continent()/",
    meta: indexzqzss_45u7_45jPSVMfSvVZ0_45hobutEpyDTj7bOZCV_gsu0Meta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/index.vue")
  },
  {
    name: "propertyType-continent___pl",
    path: "/pl/:propertyType()/:continent()/",
    meta: indexzqzss_45u7_45jPSVMfSvVZ0_45hobutEpyDTj7bOZCV_gsu0Meta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/index.vue")
  },
  {
    name: "propertyType-continent___fi",
    path: "/fi/:propertyType()/:continent()/",
    meta: indexzqzss_45u7_45jPSVMfSvVZ0_45hobutEpyDTj7bOZCV_gsu0Meta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/index.vue")
  },
  {
    name: "propertyType-continent___da",
    path: "/da/:propertyType()/:continent()/",
    meta: indexzqzss_45u7_45jPSVMfSvVZ0_45hobutEpyDTj7bOZCV_gsu0Meta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/index.vue")
  },
  {
    name: "propertyType-continent___nl",
    path: "/nl/:propertyType()/:continent()/",
    meta: indexzqzss_45u7_45jPSVMfSvVZ0_45hobutEpyDTj7bOZCV_gsu0Meta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/index.vue")
  },
  {
    name: "propertyType-continent___no",
    path: "/no/:propertyType()/:continent()/",
    meta: indexzqzss_45u7_45jPSVMfSvVZ0_45hobutEpyDTj7bOZCV_gsu0Meta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/index.vue")
  },
  {
    name: "propertyType-continent___cs",
    path: "/cs/:propertyType()/:continent()/",
    meta: indexzqzss_45u7_45jPSVMfSvVZ0_45hobutEpyDTj7bOZCV_gsu0Meta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/index.vue")
  },
  {
    name: "propertyType-continent___ru",
    path: "/ru/:propertyType()/:continent()/",
    meta: indexzqzss_45u7_45jPSVMfSvVZ0_45hobutEpyDTj7bOZCV_gsu0Meta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/index.vue")
  },
  {
    name: "propertyType-continent___tr",
    path: "/tr/:propertyType()/:continent()/",
    meta: indexzqzss_45u7_45jPSVMfSvVZ0_45hobutEpyDTj7bOZCV_gsu0Meta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/index.vue")
  },
  {
    name: "propertyType-continent___zh",
    path: "/zh/:propertyType()/:continent()/",
    meta: indexzqzss_45u7_45jPSVMfSvVZ0_45hobutEpyDTj7bOZCV_gsu0Meta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/index.vue")
  },
  {
    name: "propertyType-continent___ja",
    path: "/ja/:propertyType()/:continent()/",
    meta: indexzqzss_45u7_45jPSVMfSvVZ0_45hobutEpyDTj7bOZCV_gsu0Meta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/index.vue")
  },
  {
    name: "propertyType-continent___ko",
    path: "/ko/:propertyType()/:continent()/",
    meta: indexzqzss_45u7_45jPSVMfSvVZ0_45hobutEpyDTj7bOZCV_gsu0Meta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/index.vue")
  },
  {
    name: "groupsdataprotectionnotice___en",
    path: "/groupsdataprotectionnotice/",
    component: () => import("/build/src/pages/groupsdataprotectionnotice/index.vue")
  },
  {
    name: "groupsdataprotectionnotice___fr",
    path: "/fr/groupsdataprotectionnotice/",
    component: () => import("/build/src/pages/groupsdataprotectionnotice/index.vue")
  },
  {
    name: "groupsdataprotectionnotice___de",
    path: "/de/groupsdataprotectionnotice/",
    component: () => import("/build/src/pages/groupsdataprotectionnotice/index.vue")
  },
  {
    name: "groupsdataprotectionnotice___es",
    path: "/es/groupsdataprotectionnotice/",
    component: () => import("/build/src/pages/groupsdataprotectionnotice/index.vue")
  },
  {
    name: "groupsdataprotectionnotice___it",
    path: "/it/groupsdataprotectionnotice/",
    component: () => import("/build/src/pages/groupsdataprotectionnotice/index.vue")
  },
  {
    name: "groupsdataprotectionnotice___pt",
    path: "/pt/groupsdataprotectionnotice/",
    component: () => import("/build/src/pages/groupsdataprotectionnotice/index.vue")
  },
  {
    name: "groupsdataprotectionnotice___br",
    path: "/br/groupsdataprotectionnotice/",
    component: () => import("/build/src/pages/groupsdataprotectionnotice/index.vue")
  },
  {
    name: "groupsdataprotectionnotice___sv",
    path: "/sv/groupsdataprotectionnotice/",
    component: () => import("/build/src/pages/groupsdataprotectionnotice/index.vue")
  },
  {
    name: "groupsdataprotectionnotice___pl",
    path: "/pl/groupsdataprotectionnotice/",
    component: () => import("/build/src/pages/groupsdataprotectionnotice/index.vue")
  },
  {
    name: "groupsdataprotectionnotice___fi",
    path: "/fi/groupsdataprotectionnotice/",
    component: () => import("/build/src/pages/groupsdataprotectionnotice/index.vue")
  },
  {
    name: "groupsdataprotectionnotice___da",
    path: "/da/groupsdataprotectionnotice/",
    component: () => import("/build/src/pages/groupsdataprotectionnotice/index.vue")
  },
  {
    name: "groupsdataprotectionnotice___nl",
    path: "/nl/groupsdataprotectionnotice/",
    component: () => import("/build/src/pages/groupsdataprotectionnotice/index.vue")
  },
  {
    name: "groupsdataprotectionnotice___no",
    path: "/no/groupsdataprotectionnotice/",
    component: () => import("/build/src/pages/groupsdataprotectionnotice/index.vue")
  },
  {
    name: "groupsdataprotectionnotice___cs",
    path: "/cs/groupsdataprotectionnotice/",
    component: () => import("/build/src/pages/groupsdataprotectionnotice/index.vue")
  },
  {
    name: "groupsdataprotectionnotice___ru",
    path: "/ru/groupsdataprotectionnotice/",
    component: () => import("/build/src/pages/groupsdataprotectionnotice/index.vue")
  },
  {
    name: "groupsdataprotectionnotice___tr",
    path: "/tr/groupsdataprotectionnotice/",
    component: () => import("/build/src/pages/groupsdataprotectionnotice/index.vue")
  },
  {
    name: "groupsdataprotectionnotice___zh",
    path: "/zh/groupsdataprotectionnotice/",
    component: () => import("/build/src/pages/groupsdataprotectionnotice/index.vue")
  },
  {
    name: "groupsdataprotectionnotice___ja",
    path: "/ja/groupsdataprotectionnotice/",
    component: () => import("/build/src/pages/groupsdataprotectionnotice/index.vue")
  },
  {
    name: "groupsdataprotectionnotice___ko",
    path: "/ko/groupsdataprotectionnotice/",
    component: () => import("/build/src/pages/groupsdataprotectionnotice/index.vue")
  },
  {
    name: "propertyType-continent-country___en",
    path: "/:propertyType()/:continent()/:country()/",
    meta: index8xw5KTONVpRpd5EAeYICifOO_sMvvZdcx661NCPmr0kMeta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/index.vue")
  },
  {
    name: "propertyType-continent-country___fr",
    path: "/fr/:propertyType()/:continent()/:country()/",
    meta: index8xw5KTONVpRpd5EAeYICifOO_sMvvZdcx661NCPmr0kMeta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/index.vue")
  },
  {
    name: "propertyType-continent-country___de",
    path: "/de/:propertyType()/:continent()/:country()/",
    meta: index8xw5KTONVpRpd5EAeYICifOO_sMvvZdcx661NCPmr0kMeta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/index.vue")
  },
  {
    name: "propertyType-continent-country___es",
    path: "/es/:propertyType()/:continent()/:country()/",
    meta: index8xw5KTONVpRpd5EAeYICifOO_sMvvZdcx661NCPmr0kMeta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/index.vue")
  },
  {
    name: "propertyType-continent-country___it",
    path: "/it/:propertyType()/:continent()/:country()/",
    meta: index8xw5KTONVpRpd5EAeYICifOO_sMvvZdcx661NCPmr0kMeta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/index.vue")
  },
  {
    name: "propertyType-continent-country___pt",
    path: "/pt/:propertyType()/:continent()/:country()/",
    meta: index8xw5KTONVpRpd5EAeYICifOO_sMvvZdcx661NCPmr0kMeta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/index.vue")
  },
  {
    name: "propertyType-continent-country___br",
    path: "/br/:propertyType()/:continent()/:country()/",
    meta: index8xw5KTONVpRpd5EAeYICifOO_sMvvZdcx661NCPmr0kMeta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/index.vue")
  },
  {
    name: "propertyType-continent-country___sv",
    path: "/sv/:propertyType()/:continent()/:country()/",
    meta: index8xw5KTONVpRpd5EAeYICifOO_sMvvZdcx661NCPmr0kMeta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/index.vue")
  },
  {
    name: "propertyType-continent-country___pl",
    path: "/pl/:propertyType()/:continent()/:country()/",
    meta: index8xw5KTONVpRpd5EAeYICifOO_sMvvZdcx661NCPmr0kMeta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/index.vue")
  },
  {
    name: "propertyType-continent-country___fi",
    path: "/fi/:propertyType()/:continent()/:country()/",
    meta: index8xw5KTONVpRpd5EAeYICifOO_sMvvZdcx661NCPmr0kMeta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/index.vue")
  },
  {
    name: "propertyType-continent-country___da",
    path: "/da/:propertyType()/:continent()/:country()/",
    meta: index8xw5KTONVpRpd5EAeYICifOO_sMvvZdcx661NCPmr0kMeta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/index.vue")
  },
  {
    name: "propertyType-continent-country___nl",
    path: "/nl/:propertyType()/:continent()/:country()/",
    meta: index8xw5KTONVpRpd5EAeYICifOO_sMvvZdcx661NCPmr0kMeta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/index.vue")
  },
  {
    name: "propertyType-continent-country___no",
    path: "/no/:propertyType()/:continent()/:country()/",
    meta: index8xw5KTONVpRpd5EAeYICifOO_sMvvZdcx661NCPmr0kMeta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/index.vue")
  },
  {
    name: "propertyType-continent-country___cs",
    path: "/cs/:propertyType()/:continent()/:country()/",
    meta: index8xw5KTONVpRpd5EAeYICifOO_sMvvZdcx661NCPmr0kMeta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/index.vue")
  },
  {
    name: "propertyType-continent-country___ru",
    path: "/ru/:propertyType()/:continent()/:country()/",
    meta: index8xw5KTONVpRpd5EAeYICifOO_sMvvZdcx661NCPmr0kMeta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/index.vue")
  },
  {
    name: "propertyType-continent-country___tr",
    path: "/tr/:propertyType()/:continent()/:country()/",
    meta: index8xw5KTONVpRpd5EAeYICifOO_sMvvZdcx661NCPmr0kMeta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/index.vue")
  },
  {
    name: "propertyType-continent-country___zh",
    path: "/zh/:propertyType()/:continent()/:country()/",
    meta: index8xw5KTONVpRpd5EAeYICifOO_sMvvZdcx661NCPmr0kMeta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/index.vue")
  },
  {
    name: "propertyType-continent-country___ja",
    path: "/ja/:propertyType()/:continent()/:country()/",
    meta: index8xw5KTONVpRpd5EAeYICifOO_sMvvZdcx661NCPmr0kMeta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/index.vue")
  },
  {
    name: "propertyType-continent-country___ko",
    path: "/ko/:propertyType()/:continent()/:country()/",
    meta: index8xw5KTONVpRpd5EAeYICifOO_sMvvZdcx661NCPmr0kMeta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/index.vue")
  },
  {
    name: "propertyType-continent-country-city___en",
    path: "/:propertyType()/:continent()/:country()/:city()/",
    meta: index_45uaohzGVppUwLqxQRI2Getog_FRCD6UqUIOAIa4W1b4Meta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/index.vue")
  },
  {
    name: "propertyType-continent-country-city___fr",
    path: "/fr/:propertyType()/:continent()/:country()/:city()/",
    meta: index_45uaohzGVppUwLqxQRI2Getog_FRCD6UqUIOAIa4W1b4Meta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/index.vue")
  },
  {
    name: "propertyType-continent-country-city___de",
    path: "/de/:propertyType()/:continent()/:country()/:city()/",
    meta: index_45uaohzGVppUwLqxQRI2Getog_FRCD6UqUIOAIa4W1b4Meta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/index.vue")
  },
  {
    name: "propertyType-continent-country-city___es",
    path: "/es/:propertyType()/:continent()/:country()/:city()/",
    meta: index_45uaohzGVppUwLqxQRI2Getog_FRCD6UqUIOAIa4W1b4Meta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/index.vue")
  },
  {
    name: "propertyType-continent-country-city___it",
    path: "/it/:propertyType()/:continent()/:country()/:city()/",
    meta: index_45uaohzGVppUwLqxQRI2Getog_FRCD6UqUIOAIa4W1b4Meta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/index.vue")
  },
  {
    name: "propertyType-continent-country-city___pt",
    path: "/pt/:propertyType()/:continent()/:country()/:city()/",
    meta: index_45uaohzGVppUwLqxQRI2Getog_FRCD6UqUIOAIa4W1b4Meta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/index.vue")
  },
  {
    name: "propertyType-continent-country-city___br",
    path: "/br/:propertyType()/:continent()/:country()/:city()/",
    meta: index_45uaohzGVppUwLqxQRI2Getog_FRCD6UqUIOAIa4W1b4Meta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/index.vue")
  },
  {
    name: "propertyType-continent-country-city___sv",
    path: "/sv/:propertyType()/:continent()/:country()/:city()/",
    meta: index_45uaohzGVppUwLqxQRI2Getog_FRCD6UqUIOAIa4W1b4Meta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/index.vue")
  },
  {
    name: "propertyType-continent-country-city___pl",
    path: "/pl/:propertyType()/:continent()/:country()/:city()/",
    meta: index_45uaohzGVppUwLqxQRI2Getog_FRCD6UqUIOAIa4W1b4Meta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/index.vue")
  },
  {
    name: "propertyType-continent-country-city___fi",
    path: "/fi/:propertyType()/:continent()/:country()/:city()/",
    meta: index_45uaohzGVppUwLqxQRI2Getog_FRCD6UqUIOAIa4W1b4Meta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/index.vue")
  },
  {
    name: "propertyType-continent-country-city___da",
    path: "/da/:propertyType()/:continent()/:country()/:city()/",
    meta: index_45uaohzGVppUwLqxQRI2Getog_FRCD6UqUIOAIa4W1b4Meta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/index.vue")
  },
  {
    name: "propertyType-continent-country-city___nl",
    path: "/nl/:propertyType()/:continent()/:country()/:city()/",
    meta: index_45uaohzGVppUwLqxQRI2Getog_FRCD6UqUIOAIa4W1b4Meta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/index.vue")
  },
  {
    name: "propertyType-continent-country-city___no",
    path: "/no/:propertyType()/:continent()/:country()/:city()/",
    meta: index_45uaohzGVppUwLqxQRI2Getog_FRCD6UqUIOAIa4W1b4Meta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/index.vue")
  },
  {
    name: "propertyType-continent-country-city___cs",
    path: "/cs/:propertyType()/:continent()/:country()/:city()/",
    meta: index_45uaohzGVppUwLqxQRI2Getog_FRCD6UqUIOAIa4W1b4Meta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/index.vue")
  },
  {
    name: "propertyType-continent-country-city___ru",
    path: "/ru/:propertyType()/:continent()/:country()/:city()/",
    meta: index_45uaohzGVppUwLqxQRI2Getog_FRCD6UqUIOAIa4W1b4Meta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/index.vue")
  },
  {
    name: "propertyType-continent-country-city___tr",
    path: "/tr/:propertyType()/:continent()/:country()/:city()/",
    meta: index_45uaohzGVppUwLqxQRI2Getog_FRCD6UqUIOAIa4W1b4Meta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/index.vue")
  },
  {
    name: "propertyType-continent-country-city___zh",
    path: "/zh/:propertyType()/:continent()/:country()/:city()/",
    meta: index_45uaohzGVppUwLqxQRI2Getog_FRCD6UqUIOAIa4W1b4Meta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/index.vue")
  },
  {
    name: "propertyType-continent-country-city___ja",
    path: "/ja/:propertyType()/:continent()/:country()/:city()/",
    meta: index_45uaohzGVppUwLqxQRI2Getog_FRCD6UqUIOAIa4W1b4Meta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/index.vue")
  },
  {
    name: "propertyType-continent-country-city___ko",
    path: "/ko/:propertyType()/:continent()/:country()/:city()/",
    meta: index_45uaohzGVppUwLqxQRI2Getog_FRCD6UqUIOAIa4W1b4Meta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/index.vue")
  },
  {
    name: "propertyType-p-propertyId-propertyName___en",
    path: "/:propertyType()/p/:propertyId()/:propertyName()/",
    meta: indexhOrgrA6xMAc6QGCGwBGmYE4Ga7bv_L4vKKqyCjTcFNcMeta || {},
    component: () => import("/build/src/pages/[propertyType]/p/[propertyId]/[propertyName]/index.vue")
  },
  {
    name: "propertyType-p-propertyId-propertyName___fr",
    path: "/fr/:propertyType()/p/:propertyId()/:propertyName()/",
    meta: indexhOrgrA6xMAc6QGCGwBGmYE4Ga7bv_L4vKKqyCjTcFNcMeta || {},
    component: () => import("/build/src/pages/[propertyType]/p/[propertyId]/[propertyName]/index.vue")
  },
  {
    name: "propertyType-p-propertyId-propertyName___de",
    path: "/de/:propertyType()/p/:propertyId()/:propertyName()/",
    meta: indexhOrgrA6xMAc6QGCGwBGmYE4Ga7bv_L4vKKqyCjTcFNcMeta || {},
    component: () => import("/build/src/pages/[propertyType]/p/[propertyId]/[propertyName]/index.vue")
  },
  {
    name: "propertyType-p-propertyId-propertyName___es",
    path: "/es/:propertyType()/p/:propertyId()/:propertyName()/",
    meta: indexhOrgrA6xMAc6QGCGwBGmYE4Ga7bv_L4vKKqyCjTcFNcMeta || {},
    component: () => import("/build/src/pages/[propertyType]/p/[propertyId]/[propertyName]/index.vue")
  },
  {
    name: "propertyType-p-propertyId-propertyName___it",
    path: "/it/:propertyType()/p/:propertyId()/:propertyName()/",
    meta: indexhOrgrA6xMAc6QGCGwBGmYE4Ga7bv_L4vKKqyCjTcFNcMeta || {},
    component: () => import("/build/src/pages/[propertyType]/p/[propertyId]/[propertyName]/index.vue")
  },
  {
    name: "propertyType-p-propertyId-propertyName___pt",
    path: "/pt/:propertyType()/p/:propertyId()/:propertyName()/",
    meta: indexhOrgrA6xMAc6QGCGwBGmYE4Ga7bv_L4vKKqyCjTcFNcMeta || {},
    component: () => import("/build/src/pages/[propertyType]/p/[propertyId]/[propertyName]/index.vue")
  },
  {
    name: "propertyType-p-propertyId-propertyName___br",
    path: "/br/:propertyType()/p/:propertyId()/:propertyName()/",
    meta: indexhOrgrA6xMAc6QGCGwBGmYE4Ga7bv_L4vKKqyCjTcFNcMeta || {},
    component: () => import("/build/src/pages/[propertyType]/p/[propertyId]/[propertyName]/index.vue")
  },
  {
    name: "propertyType-p-propertyId-propertyName___sv",
    path: "/sv/:propertyType()/p/:propertyId()/:propertyName()/",
    meta: indexhOrgrA6xMAc6QGCGwBGmYE4Ga7bv_L4vKKqyCjTcFNcMeta || {},
    component: () => import("/build/src/pages/[propertyType]/p/[propertyId]/[propertyName]/index.vue")
  },
  {
    name: "propertyType-p-propertyId-propertyName___pl",
    path: "/pl/:propertyType()/p/:propertyId()/:propertyName()/",
    meta: indexhOrgrA6xMAc6QGCGwBGmYE4Ga7bv_L4vKKqyCjTcFNcMeta || {},
    component: () => import("/build/src/pages/[propertyType]/p/[propertyId]/[propertyName]/index.vue")
  },
  {
    name: "propertyType-p-propertyId-propertyName___fi",
    path: "/fi/:propertyType()/p/:propertyId()/:propertyName()/",
    meta: indexhOrgrA6xMAc6QGCGwBGmYE4Ga7bv_L4vKKqyCjTcFNcMeta || {},
    component: () => import("/build/src/pages/[propertyType]/p/[propertyId]/[propertyName]/index.vue")
  },
  {
    name: "propertyType-p-propertyId-propertyName___da",
    path: "/da/:propertyType()/p/:propertyId()/:propertyName()/",
    meta: indexhOrgrA6xMAc6QGCGwBGmYE4Ga7bv_L4vKKqyCjTcFNcMeta || {},
    component: () => import("/build/src/pages/[propertyType]/p/[propertyId]/[propertyName]/index.vue")
  },
  {
    name: "propertyType-p-propertyId-propertyName___nl",
    path: "/nl/:propertyType()/p/:propertyId()/:propertyName()/",
    meta: indexhOrgrA6xMAc6QGCGwBGmYE4Ga7bv_L4vKKqyCjTcFNcMeta || {},
    component: () => import("/build/src/pages/[propertyType]/p/[propertyId]/[propertyName]/index.vue")
  },
  {
    name: "propertyType-p-propertyId-propertyName___no",
    path: "/no/:propertyType()/p/:propertyId()/:propertyName()/",
    meta: indexhOrgrA6xMAc6QGCGwBGmYE4Ga7bv_L4vKKqyCjTcFNcMeta || {},
    component: () => import("/build/src/pages/[propertyType]/p/[propertyId]/[propertyName]/index.vue")
  },
  {
    name: "propertyType-p-propertyId-propertyName___cs",
    path: "/cs/:propertyType()/p/:propertyId()/:propertyName()/",
    meta: indexhOrgrA6xMAc6QGCGwBGmYE4Ga7bv_L4vKKqyCjTcFNcMeta || {},
    component: () => import("/build/src/pages/[propertyType]/p/[propertyId]/[propertyName]/index.vue")
  },
  {
    name: "propertyType-p-propertyId-propertyName___ru",
    path: "/ru/:propertyType()/p/:propertyId()/:propertyName()/",
    meta: indexhOrgrA6xMAc6QGCGwBGmYE4Ga7bv_L4vKKqyCjTcFNcMeta || {},
    component: () => import("/build/src/pages/[propertyType]/p/[propertyId]/[propertyName]/index.vue")
  },
  {
    name: "propertyType-p-propertyId-propertyName___tr",
    path: "/tr/:propertyType()/p/:propertyId()/:propertyName()/",
    meta: indexhOrgrA6xMAc6QGCGwBGmYE4Ga7bv_L4vKKqyCjTcFNcMeta || {},
    component: () => import("/build/src/pages/[propertyType]/p/[propertyId]/[propertyName]/index.vue")
  },
  {
    name: "propertyType-p-propertyId-propertyName___zh",
    path: "/zh/:propertyType()/p/:propertyId()/:propertyName()/",
    meta: indexhOrgrA6xMAc6QGCGwBGmYE4Ga7bv_L4vKKqyCjTcFNcMeta || {},
    component: () => import("/build/src/pages/[propertyType]/p/[propertyId]/[propertyName]/index.vue")
  },
  {
    name: "propertyType-p-propertyId-propertyName___ja",
    path: "/ja/:propertyType()/p/:propertyId()/:propertyName()/",
    meta: indexhOrgrA6xMAc6QGCGwBGmYE4Ga7bv_L4vKKqyCjTcFNcMeta || {},
    component: () => import("/build/src/pages/[propertyType]/p/[propertyId]/[propertyName]/index.vue")
  },
  {
    name: "propertyType-p-propertyId-propertyName___ko",
    path: "/ko/:propertyType()/p/:propertyId()/:propertyName()/",
    meta: indexhOrgrA6xMAc6QGCGwBGmYE4Ga7bv_L4vKKqyCjTcFNcMeta || {},
    component: () => import("/build/src/pages/[propertyType]/p/[propertyId]/[propertyName]/index.vue")
  },
  {
    name: "propertyType-continent-country-a-area___en",
    path: "/:propertyType()/:continent()/:country()/a/:area()/",
    meta: indexgehijKBjuf5w20vPvVz43I5uFgQ_45b006wWmrTq3fDTcMeta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/a/[area]/index.vue")
  },
  {
    name: "propertyType-continent-country-a-area___fr",
    path: "/fr/:propertyType()/:continent()/:country()/a/:area()/",
    meta: indexgehijKBjuf5w20vPvVz43I5uFgQ_45b006wWmrTq3fDTcMeta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/a/[area]/index.vue")
  },
  {
    name: "propertyType-continent-country-a-area___de",
    path: "/de/:propertyType()/:continent()/:country()/a/:area()/",
    meta: indexgehijKBjuf5w20vPvVz43I5uFgQ_45b006wWmrTq3fDTcMeta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/a/[area]/index.vue")
  },
  {
    name: "propertyType-continent-country-a-area___es",
    path: "/es/:propertyType()/:continent()/:country()/a/:area()/",
    meta: indexgehijKBjuf5w20vPvVz43I5uFgQ_45b006wWmrTq3fDTcMeta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/a/[area]/index.vue")
  },
  {
    name: "propertyType-continent-country-a-area___it",
    path: "/it/:propertyType()/:continent()/:country()/a/:area()/",
    meta: indexgehijKBjuf5w20vPvVz43I5uFgQ_45b006wWmrTq3fDTcMeta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/a/[area]/index.vue")
  },
  {
    name: "propertyType-continent-country-a-area___pt",
    path: "/pt/:propertyType()/:continent()/:country()/a/:area()/",
    meta: indexgehijKBjuf5w20vPvVz43I5uFgQ_45b006wWmrTq3fDTcMeta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/a/[area]/index.vue")
  },
  {
    name: "propertyType-continent-country-a-area___br",
    path: "/br/:propertyType()/:continent()/:country()/a/:area()/",
    meta: indexgehijKBjuf5w20vPvVz43I5uFgQ_45b006wWmrTq3fDTcMeta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/a/[area]/index.vue")
  },
  {
    name: "propertyType-continent-country-a-area___sv",
    path: "/sv/:propertyType()/:continent()/:country()/a/:area()/",
    meta: indexgehijKBjuf5w20vPvVz43I5uFgQ_45b006wWmrTq3fDTcMeta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/a/[area]/index.vue")
  },
  {
    name: "propertyType-continent-country-a-area___pl",
    path: "/pl/:propertyType()/:continent()/:country()/a/:area()/",
    meta: indexgehijKBjuf5w20vPvVz43I5uFgQ_45b006wWmrTq3fDTcMeta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/a/[area]/index.vue")
  },
  {
    name: "propertyType-continent-country-a-area___fi",
    path: "/fi/:propertyType()/:continent()/:country()/a/:area()/",
    meta: indexgehijKBjuf5w20vPvVz43I5uFgQ_45b006wWmrTq3fDTcMeta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/a/[area]/index.vue")
  },
  {
    name: "propertyType-continent-country-a-area___da",
    path: "/da/:propertyType()/:continent()/:country()/a/:area()/",
    meta: indexgehijKBjuf5w20vPvVz43I5uFgQ_45b006wWmrTq3fDTcMeta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/a/[area]/index.vue")
  },
  {
    name: "propertyType-continent-country-a-area___nl",
    path: "/nl/:propertyType()/:continent()/:country()/a/:area()/",
    meta: indexgehijKBjuf5w20vPvVz43I5uFgQ_45b006wWmrTq3fDTcMeta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/a/[area]/index.vue")
  },
  {
    name: "propertyType-continent-country-a-area___no",
    path: "/no/:propertyType()/:continent()/:country()/a/:area()/",
    meta: indexgehijKBjuf5w20vPvVz43I5uFgQ_45b006wWmrTq3fDTcMeta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/a/[area]/index.vue")
  },
  {
    name: "propertyType-continent-country-a-area___cs",
    path: "/cs/:propertyType()/:continent()/:country()/a/:area()/",
    meta: indexgehijKBjuf5w20vPvVz43I5uFgQ_45b006wWmrTq3fDTcMeta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/a/[area]/index.vue")
  },
  {
    name: "propertyType-continent-country-a-area___ru",
    path: "/ru/:propertyType()/:continent()/:country()/a/:area()/",
    meta: indexgehijKBjuf5w20vPvVz43I5uFgQ_45b006wWmrTq3fDTcMeta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/a/[area]/index.vue")
  },
  {
    name: "propertyType-continent-country-a-area___tr",
    path: "/tr/:propertyType()/:continent()/:country()/a/:area()/",
    meta: indexgehijKBjuf5w20vPvVz43I5uFgQ_45b006wWmrTq3fDTcMeta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/a/[area]/index.vue")
  },
  {
    name: "propertyType-continent-country-a-area___zh",
    path: "/zh/:propertyType()/:continent()/:country()/a/:area()/",
    meta: indexgehijKBjuf5w20vPvVz43I5uFgQ_45b006wWmrTq3fDTcMeta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/a/[area]/index.vue")
  },
  {
    name: "propertyType-continent-country-a-area___ja",
    path: "/ja/:propertyType()/:continent()/:country()/a/:area()/",
    meta: indexgehijKBjuf5w20vPvVz43I5uFgQ_45b006wWmrTq3fDTcMeta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/a/[area]/index.vue")
  },
  {
    name: "propertyType-continent-country-a-area___ko",
    path: "/ko/:propertyType()/:continent()/:country()/a/:area()/",
    meta: indexgehijKBjuf5w20vPvVz43I5uFgQ_45b006wWmrTq3fDTcMeta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/a/[area]/index.vue")
  },
  {
    name: "propertyType-continent-country-r-region___en",
    path: "/:propertyType()/:continent()/:country()/r/:region()/",
    meta: indexoRUrXH6EfRiax1vphzwYgC5zJzQrtk9Kz1uczWAbNF8Meta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/r/[region]/index.vue")
  },
  {
    name: "propertyType-continent-country-r-region___fr",
    path: "/fr/:propertyType()/:continent()/:country()/r/:region()/",
    meta: indexoRUrXH6EfRiax1vphzwYgC5zJzQrtk9Kz1uczWAbNF8Meta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/r/[region]/index.vue")
  },
  {
    name: "propertyType-continent-country-r-region___de",
    path: "/de/:propertyType()/:continent()/:country()/r/:region()/",
    meta: indexoRUrXH6EfRiax1vphzwYgC5zJzQrtk9Kz1uczWAbNF8Meta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/r/[region]/index.vue")
  },
  {
    name: "propertyType-continent-country-r-region___es",
    path: "/es/:propertyType()/:continent()/:country()/r/:region()/",
    meta: indexoRUrXH6EfRiax1vphzwYgC5zJzQrtk9Kz1uczWAbNF8Meta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/r/[region]/index.vue")
  },
  {
    name: "propertyType-continent-country-r-region___it",
    path: "/it/:propertyType()/:continent()/:country()/r/:region()/",
    meta: indexoRUrXH6EfRiax1vphzwYgC5zJzQrtk9Kz1uczWAbNF8Meta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/r/[region]/index.vue")
  },
  {
    name: "propertyType-continent-country-r-region___pt",
    path: "/pt/:propertyType()/:continent()/:country()/r/:region()/",
    meta: indexoRUrXH6EfRiax1vphzwYgC5zJzQrtk9Kz1uczWAbNF8Meta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/r/[region]/index.vue")
  },
  {
    name: "propertyType-continent-country-r-region___br",
    path: "/br/:propertyType()/:continent()/:country()/r/:region()/",
    meta: indexoRUrXH6EfRiax1vphzwYgC5zJzQrtk9Kz1uczWAbNF8Meta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/r/[region]/index.vue")
  },
  {
    name: "propertyType-continent-country-r-region___sv",
    path: "/sv/:propertyType()/:continent()/:country()/r/:region()/",
    meta: indexoRUrXH6EfRiax1vphzwYgC5zJzQrtk9Kz1uczWAbNF8Meta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/r/[region]/index.vue")
  },
  {
    name: "propertyType-continent-country-r-region___pl",
    path: "/pl/:propertyType()/:continent()/:country()/r/:region()/",
    meta: indexoRUrXH6EfRiax1vphzwYgC5zJzQrtk9Kz1uczWAbNF8Meta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/r/[region]/index.vue")
  },
  {
    name: "propertyType-continent-country-r-region___fi",
    path: "/fi/:propertyType()/:continent()/:country()/r/:region()/",
    meta: indexoRUrXH6EfRiax1vphzwYgC5zJzQrtk9Kz1uczWAbNF8Meta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/r/[region]/index.vue")
  },
  {
    name: "propertyType-continent-country-r-region___da",
    path: "/da/:propertyType()/:continent()/:country()/r/:region()/",
    meta: indexoRUrXH6EfRiax1vphzwYgC5zJzQrtk9Kz1uczWAbNF8Meta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/r/[region]/index.vue")
  },
  {
    name: "propertyType-continent-country-r-region___nl",
    path: "/nl/:propertyType()/:continent()/:country()/r/:region()/",
    meta: indexoRUrXH6EfRiax1vphzwYgC5zJzQrtk9Kz1uczWAbNF8Meta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/r/[region]/index.vue")
  },
  {
    name: "propertyType-continent-country-r-region___no",
    path: "/no/:propertyType()/:continent()/:country()/r/:region()/",
    meta: indexoRUrXH6EfRiax1vphzwYgC5zJzQrtk9Kz1uczWAbNF8Meta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/r/[region]/index.vue")
  },
  {
    name: "propertyType-continent-country-r-region___cs",
    path: "/cs/:propertyType()/:continent()/:country()/r/:region()/",
    meta: indexoRUrXH6EfRiax1vphzwYgC5zJzQrtk9Kz1uczWAbNF8Meta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/r/[region]/index.vue")
  },
  {
    name: "propertyType-continent-country-r-region___ru",
    path: "/ru/:propertyType()/:continent()/:country()/r/:region()/",
    meta: indexoRUrXH6EfRiax1vphzwYgC5zJzQrtk9Kz1uczWAbNF8Meta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/r/[region]/index.vue")
  },
  {
    name: "propertyType-continent-country-r-region___tr",
    path: "/tr/:propertyType()/:continent()/:country()/r/:region()/",
    meta: indexoRUrXH6EfRiax1vphzwYgC5zJzQrtk9Kz1uczWAbNF8Meta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/r/[region]/index.vue")
  },
  {
    name: "propertyType-continent-country-r-region___zh",
    path: "/zh/:propertyType()/:continent()/:country()/r/:region()/",
    meta: indexoRUrXH6EfRiax1vphzwYgC5zJzQrtk9Kz1uczWAbNF8Meta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/r/[region]/index.vue")
  },
  {
    name: "propertyType-continent-country-r-region___ja",
    path: "/ja/:propertyType()/:continent()/:country()/r/:region()/",
    meta: indexoRUrXH6EfRiax1vphzwYgC5zJzQrtk9Kz1uczWAbNF8Meta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/r/[region]/index.vue")
  },
  {
    name: "propertyType-continent-country-r-region___ko",
    path: "/ko/:propertyType()/:continent()/:country()/r/:region()/",
    meta: indexoRUrXH6EfRiax1vphzwYgC5zJzQrtk9Kz1uczWAbNF8Meta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/r/[region]/index.vue")
  },
  {
    name: "propertyType-continent-country-city-d-district___en",
    path: "/:propertyType()/:continent()/:country()/:city()/d/:district()/",
    meta: indexzXFRgYiZfyLgpwwVQ14f8C1WB_4S0_45B76G805XD81xAMeta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/d/[district]/index.vue")
  },
  {
    name: "propertyType-continent-country-city-d-district___fr",
    path: "/fr/:propertyType()/:continent()/:country()/:city()/d/:district()/",
    meta: indexzXFRgYiZfyLgpwwVQ14f8C1WB_4S0_45B76G805XD81xAMeta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/d/[district]/index.vue")
  },
  {
    name: "propertyType-continent-country-city-d-district___de",
    path: "/de/:propertyType()/:continent()/:country()/:city()/d/:district()/",
    meta: indexzXFRgYiZfyLgpwwVQ14f8C1WB_4S0_45B76G805XD81xAMeta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/d/[district]/index.vue")
  },
  {
    name: "propertyType-continent-country-city-d-district___es",
    path: "/es/:propertyType()/:continent()/:country()/:city()/d/:district()/",
    meta: indexzXFRgYiZfyLgpwwVQ14f8C1WB_4S0_45B76G805XD81xAMeta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/d/[district]/index.vue")
  },
  {
    name: "propertyType-continent-country-city-d-district___it",
    path: "/it/:propertyType()/:continent()/:country()/:city()/d/:district()/",
    meta: indexzXFRgYiZfyLgpwwVQ14f8C1WB_4S0_45B76G805XD81xAMeta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/d/[district]/index.vue")
  },
  {
    name: "propertyType-continent-country-city-d-district___pt",
    path: "/pt/:propertyType()/:continent()/:country()/:city()/d/:district()/",
    meta: indexzXFRgYiZfyLgpwwVQ14f8C1WB_4S0_45B76G805XD81xAMeta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/d/[district]/index.vue")
  },
  {
    name: "propertyType-continent-country-city-d-district___br",
    path: "/br/:propertyType()/:continent()/:country()/:city()/d/:district()/",
    meta: indexzXFRgYiZfyLgpwwVQ14f8C1WB_4S0_45B76G805XD81xAMeta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/d/[district]/index.vue")
  },
  {
    name: "propertyType-continent-country-city-d-district___sv",
    path: "/sv/:propertyType()/:continent()/:country()/:city()/d/:district()/",
    meta: indexzXFRgYiZfyLgpwwVQ14f8C1WB_4S0_45B76G805XD81xAMeta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/d/[district]/index.vue")
  },
  {
    name: "propertyType-continent-country-city-d-district___pl",
    path: "/pl/:propertyType()/:continent()/:country()/:city()/d/:district()/",
    meta: indexzXFRgYiZfyLgpwwVQ14f8C1WB_4S0_45B76G805XD81xAMeta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/d/[district]/index.vue")
  },
  {
    name: "propertyType-continent-country-city-d-district___fi",
    path: "/fi/:propertyType()/:continent()/:country()/:city()/d/:district()/",
    meta: indexzXFRgYiZfyLgpwwVQ14f8C1WB_4S0_45B76G805XD81xAMeta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/d/[district]/index.vue")
  },
  {
    name: "propertyType-continent-country-city-d-district___da",
    path: "/da/:propertyType()/:continent()/:country()/:city()/d/:district()/",
    meta: indexzXFRgYiZfyLgpwwVQ14f8C1WB_4S0_45B76G805XD81xAMeta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/d/[district]/index.vue")
  },
  {
    name: "propertyType-continent-country-city-d-district___nl",
    path: "/nl/:propertyType()/:continent()/:country()/:city()/d/:district()/",
    meta: indexzXFRgYiZfyLgpwwVQ14f8C1WB_4S0_45B76G805XD81xAMeta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/d/[district]/index.vue")
  },
  {
    name: "propertyType-continent-country-city-d-district___no",
    path: "/no/:propertyType()/:continent()/:country()/:city()/d/:district()/",
    meta: indexzXFRgYiZfyLgpwwVQ14f8C1WB_4S0_45B76G805XD81xAMeta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/d/[district]/index.vue")
  },
  {
    name: "propertyType-continent-country-city-d-district___cs",
    path: "/cs/:propertyType()/:continent()/:country()/:city()/d/:district()/",
    meta: indexzXFRgYiZfyLgpwwVQ14f8C1WB_4S0_45B76G805XD81xAMeta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/d/[district]/index.vue")
  },
  {
    name: "propertyType-continent-country-city-d-district___ru",
    path: "/ru/:propertyType()/:continent()/:country()/:city()/d/:district()/",
    meta: indexzXFRgYiZfyLgpwwVQ14f8C1WB_4S0_45B76G805XD81xAMeta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/d/[district]/index.vue")
  },
  {
    name: "propertyType-continent-country-city-d-district___tr",
    path: "/tr/:propertyType()/:continent()/:country()/:city()/d/:district()/",
    meta: indexzXFRgYiZfyLgpwwVQ14f8C1WB_4S0_45B76G805XD81xAMeta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/d/[district]/index.vue")
  },
  {
    name: "propertyType-continent-country-city-d-district___zh",
    path: "/zh/:propertyType()/:continent()/:country()/:city()/d/:district()/",
    meta: indexzXFRgYiZfyLgpwwVQ14f8C1WB_4S0_45B76G805XD81xAMeta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/d/[district]/index.vue")
  },
  {
    name: "propertyType-continent-country-city-d-district___ja",
    path: "/ja/:propertyType()/:continent()/:country()/:city()/d/:district()/",
    meta: indexzXFRgYiZfyLgpwwVQ14f8C1WB_4S0_45B76G805XD81xAMeta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/d/[district]/index.vue")
  },
  {
    name: "propertyType-continent-country-city-d-district___ko",
    path: "/ko/:propertyType()/:continent()/:country()/:city()/d/:district()/",
    meta: indexzXFRgYiZfyLgpwwVQ14f8C1WB_4S0_45B76G805XD81xAMeta || {},
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/d/[district]/index.vue")
  },
  {
    name: index_45uaohzGVppUwLqxQRI2Getog_FRCD6UqUIOAIa4W1b4Meta?.name,
    path: "/:propertyType/:continent/:country/:city/p/:pageNumber/",
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/index.vue")
  },
  {
    name: index_45uaohzGVppUwLqxQRI2Getog_FRCD6UqUIOAIa4W1b4Meta?.name,
    path: "/fr/:propertyType/:continent/:country/:city/p/:pageNumber/",
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/index.vue")
  },
  {
    name: index_45uaohzGVppUwLqxQRI2Getog_FRCD6UqUIOAIa4W1b4Meta?.name,
    path: "/de/:propertyType/:continent/:country/:city/p/:pageNumber/",
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/index.vue")
  },
  {
    name: index_45uaohzGVppUwLqxQRI2Getog_FRCD6UqUIOAIa4W1b4Meta?.name,
    path: "/es/:propertyType/:continent/:country/:city/p/:pageNumber/",
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/index.vue")
  },
  {
    name: index_45uaohzGVppUwLqxQRI2Getog_FRCD6UqUIOAIa4W1b4Meta?.name,
    path: "/it/:propertyType/:continent/:country/:city/p/:pageNumber/",
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/index.vue")
  },
  {
    name: index_45uaohzGVppUwLqxQRI2Getog_FRCD6UqUIOAIa4W1b4Meta?.name,
    path: "/pt/:propertyType/:continent/:country/:city/p/:pageNumber/",
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/index.vue")
  },
  {
    name: index_45uaohzGVppUwLqxQRI2Getog_FRCD6UqUIOAIa4W1b4Meta?.name,
    path: "/br/:propertyType/:continent/:country/:city/p/:pageNumber/",
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/index.vue")
  },
  {
    name: index_45uaohzGVppUwLqxQRI2Getog_FRCD6UqUIOAIa4W1b4Meta?.name,
    path: "/sv/:propertyType/:continent/:country/:city/p/:pageNumber/",
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/index.vue")
  },
  {
    name: index_45uaohzGVppUwLqxQRI2Getog_FRCD6UqUIOAIa4W1b4Meta?.name,
    path: "/pl/:propertyType/:continent/:country/:city/p/:pageNumber/",
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/index.vue")
  },
  {
    name: index_45uaohzGVppUwLqxQRI2Getog_FRCD6UqUIOAIa4W1b4Meta?.name,
    path: "/fi/:propertyType/:continent/:country/:city/p/:pageNumber/",
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/index.vue")
  },
  {
    name: index_45uaohzGVppUwLqxQRI2Getog_FRCD6UqUIOAIa4W1b4Meta?.name,
    path: "/da/:propertyType/:continent/:country/:city/p/:pageNumber/",
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/index.vue")
  },
  {
    name: index_45uaohzGVppUwLqxQRI2Getog_FRCD6UqUIOAIa4W1b4Meta?.name,
    path: "/nl/:propertyType/:continent/:country/:city/p/:pageNumber/",
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/index.vue")
  },
  {
    name: index_45uaohzGVppUwLqxQRI2Getog_FRCD6UqUIOAIa4W1b4Meta?.name,
    path: "/no/:propertyType/:continent/:country/:city/p/:pageNumber/",
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/index.vue")
  },
  {
    name: index_45uaohzGVppUwLqxQRI2Getog_FRCD6UqUIOAIa4W1b4Meta?.name,
    path: "/cs/:propertyType/:continent/:country/:city/p/:pageNumber/",
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/index.vue")
  },
  {
    name: index_45uaohzGVppUwLqxQRI2Getog_FRCD6UqUIOAIa4W1b4Meta?.name,
    path: "/ru/:propertyType/:continent/:country/:city/p/:pageNumber/",
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/index.vue")
  },
  {
    name: index_45uaohzGVppUwLqxQRI2Getog_FRCD6UqUIOAIa4W1b4Meta?.name,
    path: "/tr/:propertyType/:continent/:country/:city/p/:pageNumber/",
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/index.vue")
  },
  {
    name: index_45uaohzGVppUwLqxQRI2Getog_FRCD6UqUIOAIa4W1b4Meta?.name,
    path: "/zh/:propertyType/:continent/:country/:city/p/:pageNumber/",
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/index.vue")
  },
  {
    name: index_45uaohzGVppUwLqxQRI2Getog_FRCD6UqUIOAIa4W1b4Meta?.name,
    path: "/ja/:propertyType/:continent/:country/:city/p/:pageNumber/",
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/index.vue")
  },
  {
    name: index_45uaohzGVppUwLqxQRI2Getog_FRCD6UqUIOAIa4W1b4Meta?.name,
    path: "/ko/:propertyType/:continent/:country/:city/p/:pageNumber/",
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/index.vue")
  },
  {
    name: index_45uaohzGVppUwLqxQRI2Getog_FRCD6UqUIOAIa4W1b4Meta?.name,
    path: "/:propertyType/:continent/:country/:city/f/:filter/",
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/index.vue")
  },
  {
    name: index_45uaohzGVppUwLqxQRI2Getog_FRCD6UqUIOAIa4W1b4Meta?.name,
    path: "/fr/:propertyType/:continent/:country/:city/f/:filter/",
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/index.vue")
  },
  {
    name: index_45uaohzGVppUwLqxQRI2Getog_FRCD6UqUIOAIa4W1b4Meta?.name,
    path: "/de/:propertyType/:continent/:country/:city/f/:filter/",
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/index.vue")
  },
  {
    name: index_45uaohzGVppUwLqxQRI2Getog_FRCD6UqUIOAIa4W1b4Meta?.name,
    path: "/es/:propertyType/:continent/:country/:city/f/:filter/",
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/index.vue")
  },
  {
    name: index_45uaohzGVppUwLqxQRI2Getog_FRCD6UqUIOAIa4W1b4Meta?.name,
    path: "/it/:propertyType/:continent/:country/:city/f/:filter/",
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/index.vue")
  },
  {
    name: index_45uaohzGVppUwLqxQRI2Getog_FRCD6UqUIOAIa4W1b4Meta?.name,
    path: "/pt/:propertyType/:continent/:country/:city/f/:filter/",
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/index.vue")
  },
  {
    name: index_45uaohzGVppUwLqxQRI2Getog_FRCD6UqUIOAIa4W1b4Meta?.name,
    path: "/br/:propertyType/:continent/:country/:city/f/:filter/",
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/index.vue")
  },
  {
    name: index_45uaohzGVppUwLqxQRI2Getog_FRCD6UqUIOAIa4W1b4Meta?.name,
    path: "/sv/:propertyType/:continent/:country/:city/f/:filter/",
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/index.vue")
  },
  {
    name: index_45uaohzGVppUwLqxQRI2Getog_FRCD6UqUIOAIa4W1b4Meta?.name,
    path: "/pl/:propertyType/:continent/:country/:city/f/:filter/",
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/index.vue")
  },
  {
    name: index_45uaohzGVppUwLqxQRI2Getog_FRCD6UqUIOAIa4W1b4Meta?.name,
    path: "/fi/:propertyType/:continent/:country/:city/f/:filter/",
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/index.vue")
  },
  {
    name: index_45uaohzGVppUwLqxQRI2Getog_FRCD6UqUIOAIa4W1b4Meta?.name,
    path: "/da/:propertyType/:continent/:country/:city/f/:filter/",
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/index.vue")
  },
  {
    name: index_45uaohzGVppUwLqxQRI2Getog_FRCD6UqUIOAIa4W1b4Meta?.name,
    path: "/nl/:propertyType/:continent/:country/:city/f/:filter/",
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/index.vue")
  },
  {
    name: index_45uaohzGVppUwLqxQRI2Getog_FRCD6UqUIOAIa4W1b4Meta?.name,
    path: "/no/:propertyType/:continent/:country/:city/f/:filter/",
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/index.vue")
  },
  {
    name: index_45uaohzGVppUwLqxQRI2Getog_FRCD6UqUIOAIa4W1b4Meta?.name,
    path: "/cs/:propertyType/:continent/:country/:city/f/:filter/",
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/index.vue")
  },
  {
    name: index_45uaohzGVppUwLqxQRI2Getog_FRCD6UqUIOAIa4W1b4Meta?.name,
    path: "/ru/:propertyType/:continent/:country/:city/f/:filter/",
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/index.vue")
  },
  {
    name: index_45uaohzGVppUwLqxQRI2Getog_FRCD6UqUIOAIa4W1b4Meta?.name,
    path: "/tr/:propertyType/:continent/:country/:city/f/:filter/",
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/index.vue")
  },
  {
    name: index_45uaohzGVppUwLqxQRI2Getog_FRCD6UqUIOAIa4W1b4Meta?.name,
    path: "/zh/:propertyType/:continent/:country/:city/f/:filter/",
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/index.vue")
  },
  {
    name: index_45uaohzGVppUwLqxQRI2Getog_FRCD6UqUIOAIa4W1b4Meta?.name,
    path: "/ja/:propertyType/:continent/:country/:city/f/:filter/",
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/index.vue")
  },
  {
    name: index_45uaohzGVppUwLqxQRI2Getog_FRCD6UqUIOAIa4W1b4Meta?.name,
    path: "/ko/:propertyType/:continent/:country/:city/f/:filter/",
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/index.vue")
  },
  {
    name: index_45uaohzGVppUwLqxQRI2Getog_FRCD6UqUIOAIa4W1b4Meta?.name,
    path: "/:propertyType/:continent/:country/:city/f/:filter/p/:pageNumber/",
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/index.vue")
  },
  {
    name: index_45uaohzGVppUwLqxQRI2Getog_FRCD6UqUIOAIa4W1b4Meta?.name,
    path: "/fr/:propertyType/:continent/:country/:city/f/:filter/p/:pageNumber/",
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/index.vue")
  },
  {
    name: index_45uaohzGVppUwLqxQRI2Getog_FRCD6UqUIOAIa4W1b4Meta?.name,
    path: "/de/:propertyType/:continent/:country/:city/f/:filter/p/:pageNumber/",
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/index.vue")
  },
  {
    name: index_45uaohzGVppUwLqxQRI2Getog_FRCD6UqUIOAIa4W1b4Meta?.name,
    path: "/es/:propertyType/:continent/:country/:city/f/:filter/p/:pageNumber/",
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/index.vue")
  },
  {
    name: index_45uaohzGVppUwLqxQRI2Getog_FRCD6UqUIOAIa4W1b4Meta?.name,
    path: "/it/:propertyType/:continent/:country/:city/f/:filter/p/:pageNumber/",
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/index.vue")
  },
  {
    name: index_45uaohzGVppUwLqxQRI2Getog_FRCD6UqUIOAIa4W1b4Meta?.name,
    path: "/pt/:propertyType/:continent/:country/:city/f/:filter/p/:pageNumber/",
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/index.vue")
  },
  {
    name: index_45uaohzGVppUwLqxQRI2Getog_FRCD6UqUIOAIa4W1b4Meta?.name,
    path: "/br/:propertyType/:continent/:country/:city/f/:filter/p/:pageNumber/",
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/index.vue")
  },
  {
    name: index_45uaohzGVppUwLqxQRI2Getog_FRCD6UqUIOAIa4W1b4Meta?.name,
    path: "/sv/:propertyType/:continent/:country/:city/f/:filter/p/:pageNumber/",
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/index.vue")
  },
  {
    name: index_45uaohzGVppUwLqxQRI2Getog_FRCD6UqUIOAIa4W1b4Meta?.name,
    path: "/pl/:propertyType/:continent/:country/:city/f/:filter/p/:pageNumber/",
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/index.vue")
  },
  {
    name: index_45uaohzGVppUwLqxQRI2Getog_FRCD6UqUIOAIa4W1b4Meta?.name,
    path: "/fi/:propertyType/:continent/:country/:city/f/:filter/p/:pageNumber/",
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/index.vue")
  },
  {
    name: index_45uaohzGVppUwLqxQRI2Getog_FRCD6UqUIOAIa4W1b4Meta?.name,
    path: "/da/:propertyType/:continent/:country/:city/f/:filter/p/:pageNumber/",
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/index.vue")
  },
  {
    name: index_45uaohzGVppUwLqxQRI2Getog_FRCD6UqUIOAIa4W1b4Meta?.name,
    path: "/nl/:propertyType/:continent/:country/:city/f/:filter/p/:pageNumber/",
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/index.vue")
  },
  {
    name: index_45uaohzGVppUwLqxQRI2Getog_FRCD6UqUIOAIa4W1b4Meta?.name,
    path: "/no/:propertyType/:continent/:country/:city/f/:filter/p/:pageNumber/",
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/index.vue")
  },
  {
    name: index_45uaohzGVppUwLqxQRI2Getog_FRCD6UqUIOAIa4W1b4Meta?.name,
    path: "/cs/:propertyType/:continent/:country/:city/f/:filter/p/:pageNumber/",
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/index.vue")
  },
  {
    name: index_45uaohzGVppUwLqxQRI2Getog_FRCD6UqUIOAIa4W1b4Meta?.name,
    path: "/ru/:propertyType/:continent/:country/:city/f/:filter/p/:pageNumber/",
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/index.vue")
  },
  {
    name: index_45uaohzGVppUwLqxQRI2Getog_FRCD6UqUIOAIa4W1b4Meta?.name,
    path: "/tr/:propertyType/:continent/:country/:city/f/:filter/p/:pageNumber/",
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/index.vue")
  },
  {
    name: index_45uaohzGVppUwLqxQRI2Getog_FRCD6UqUIOAIa4W1b4Meta?.name,
    path: "/zh/:propertyType/:continent/:country/:city/f/:filter/p/:pageNumber/",
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/index.vue")
  },
  {
    name: index_45uaohzGVppUwLqxQRI2Getog_FRCD6UqUIOAIa4W1b4Meta?.name,
    path: "/ja/:propertyType/:continent/:country/:city/f/:filter/p/:pageNumber/",
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/index.vue")
  },
  {
    name: index_45uaohzGVppUwLqxQRI2Getog_FRCD6UqUIOAIa4W1b4Meta?.name,
    path: "/ko/:propertyType/:continent/:country/:city/f/:filter/p/:pageNumber/",
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/index.vue")
  },
  {
    name: indexzXFRgYiZfyLgpwwVQ14f8C1WB_4S0_45B76G805XD81xAMeta?.name,
    path: "/:propertyType/:continent/:country/:city/d/:district/p/:pageNumber/",
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/d/[district]/index.vue")
  },
  {
    name: indexzXFRgYiZfyLgpwwVQ14f8C1WB_4S0_45B76G805XD81xAMeta?.name,
    path: "/fr/:propertyType/:continent/:country/:city/d/:district/p/:pageNumber/",
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/d/[district]/index.vue")
  },
  {
    name: indexzXFRgYiZfyLgpwwVQ14f8C1WB_4S0_45B76G805XD81xAMeta?.name,
    path: "/de/:propertyType/:continent/:country/:city/d/:district/p/:pageNumber/",
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/d/[district]/index.vue")
  },
  {
    name: indexzXFRgYiZfyLgpwwVQ14f8C1WB_4S0_45B76G805XD81xAMeta?.name,
    path: "/es/:propertyType/:continent/:country/:city/d/:district/p/:pageNumber/",
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/d/[district]/index.vue")
  },
  {
    name: indexzXFRgYiZfyLgpwwVQ14f8C1WB_4S0_45B76G805XD81xAMeta?.name,
    path: "/it/:propertyType/:continent/:country/:city/d/:district/p/:pageNumber/",
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/d/[district]/index.vue")
  },
  {
    name: indexzXFRgYiZfyLgpwwVQ14f8C1WB_4S0_45B76G805XD81xAMeta?.name,
    path: "/pt/:propertyType/:continent/:country/:city/d/:district/p/:pageNumber/",
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/d/[district]/index.vue")
  },
  {
    name: indexzXFRgYiZfyLgpwwVQ14f8C1WB_4S0_45B76G805XD81xAMeta?.name,
    path: "/br/:propertyType/:continent/:country/:city/d/:district/p/:pageNumber/",
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/d/[district]/index.vue")
  },
  {
    name: indexzXFRgYiZfyLgpwwVQ14f8C1WB_4S0_45B76G805XD81xAMeta?.name,
    path: "/sv/:propertyType/:continent/:country/:city/d/:district/p/:pageNumber/",
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/d/[district]/index.vue")
  },
  {
    name: indexzXFRgYiZfyLgpwwVQ14f8C1WB_4S0_45B76G805XD81xAMeta?.name,
    path: "/pl/:propertyType/:continent/:country/:city/d/:district/p/:pageNumber/",
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/d/[district]/index.vue")
  },
  {
    name: indexzXFRgYiZfyLgpwwVQ14f8C1WB_4S0_45B76G805XD81xAMeta?.name,
    path: "/fi/:propertyType/:continent/:country/:city/d/:district/p/:pageNumber/",
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/d/[district]/index.vue")
  },
  {
    name: indexzXFRgYiZfyLgpwwVQ14f8C1WB_4S0_45B76G805XD81xAMeta?.name,
    path: "/da/:propertyType/:continent/:country/:city/d/:district/p/:pageNumber/",
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/d/[district]/index.vue")
  },
  {
    name: indexzXFRgYiZfyLgpwwVQ14f8C1WB_4S0_45B76G805XD81xAMeta?.name,
    path: "/nl/:propertyType/:continent/:country/:city/d/:district/p/:pageNumber/",
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/d/[district]/index.vue")
  },
  {
    name: indexzXFRgYiZfyLgpwwVQ14f8C1WB_4S0_45B76G805XD81xAMeta?.name,
    path: "/no/:propertyType/:continent/:country/:city/d/:district/p/:pageNumber/",
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/d/[district]/index.vue")
  },
  {
    name: indexzXFRgYiZfyLgpwwVQ14f8C1WB_4S0_45B76G805XD81xAMeta?.name,
    path: "/cs/:propertyType/:continent/:country/:city/d/:district/p/:pageNumber/",
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/d/[district]/index.vue")
  },
  {
    name: indexzXFRgYiZfyLgpwwVQ14f8C1WB_4S0_45B76G805XD81xAMeta?.name,
    path: "/ru/:propertyType/:continent/:country/:city/d/:district/p/:pageNumber/",
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/d/[district]/index.vue")
  },
  {
    name: indexzXFRgYiZfyLgpwwVQ14f8C1WB_4S0_45B76G805XD81xAMeta?.name,
    path: "/tr/:propertyType/:continent/:country/:city/d/:district/p/:pageNumber/",
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/d/[district]/index.vue")
  },
  {
    name: indexzXFRgYiZfyLgpwwVQ14f8C1WB_4S0_45B76G805XD81xAMeta?.name,
    path: "/zh/:propertyType/:continent/:country/:city/d/:district/p/:pageNumber/",
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/d/[district]/index.vue")
  },
  {
    name: indexzXFRgYiZfyLgpwwVQ14f8C1WB_4S0_45B76G805XD81xAMeta?.name,
    path: "/ja/:propertyType/:continent/:country/:city/d/:district/p/:pageNumber/",
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/d/[district]/index.vue")
  },
  {
    name: indexzXFRgYiZfyLgpwwVQ14f8C1WB_4S0_45B76G805XD81xAMeta?.name,
    path: "/ko/:propertyType/:continent/:country/:city/d/:district/p/:pageNumber/",
    component: () => import("/build/src/pages/[propertyType]/[continent]/[country]/[city]/d/[district]/index.vue")
  }
]