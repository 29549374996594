
// @ts-nocheck


export const localeCodes =  [
  "en",
  "fr",
  "de",
  "es",
  "it",
  "pt",
  "br",
  "sv",
  "pl",
  "fi",
  "da",
  "nl",
  "no",
  "cs",
  "ru",
  "tr",
  "zh",
  "ja",
  "ko"
]

export const localeLoaders = {
  en: [
    {
      key: "locale_en_GB_46json_cba0960b",
      load: () => import("#nuxt-i18n/cba0960b" /* webpackChunkName: "locale_en_GB_46json_cba0960b" */),
      cache: true
    }
  ],
  fr: [
    {
      key: "locale_fr_FR_46json_042f0c6e",
      load: () => import("#nuxt-i18n/042f0c6e" /* webpackChunkName: "locale_fr_FR_46json_042f0c6e" */),
      cache: true
    }
  ],
  de: [
    {
      key: "locale_de_DE_46json_3b5fc314",
      load: () => import("#nuxt-i18n/3b5fc314" /* webpackChunkName: "locale_de_DE_46json_3b5fc314" */),
      cache: true
    }
  ],
  es: [
    {
      key: "locale_es_ES_46json_3ca79d02",
      load: () => import("#nuxt-i18n/3ca79d02" /* webpackChunkName: "locale_es_ES_46json_3ca79d02" */),
      cache: true
    }
  ],
  it: [
    {
      key: "locale_it_IT_46json_b860ff70",
      load: () => import("#nuxt-i18n/b860ff70" /* webpackChunkName: "locale_it_IT_46json_b860ff70" */),
      cache: true
    }
  ],
  pt: [
    {
      key: "locale_pt_PT_46json_731b63df",
      load: () => import("#nuxt-i18n/731b63df" /* webpackChunkName: "locale_pt_PT_46json_731b63df" */),
      cache: true
    }
  ],
  br: [
    {
      key: "locale_pt_BR_46json_c529f0d0",
      load: () => import("#nuxt-i18n/c529f0d0" /* webpackChunkName: "locale_pt_BR_46json_c529f0d0" */),
      cache: true
    }
  ],
  sv: [
    {
      key: "locale_sv_SE_46json_8ea3cad3",
      load: () => import("#nuxt-i18n/8ea3cad3" /* webpackChunkName: "locale_sv_SE_46json_8ea3cad3" */),
      cache: true
    }
  ],
  pl: [
    {
      key: "locale_pl_PL_46json_11e14e3f",
      load: () => import("#nuxt-i18n/11e14e3f" /* webpackChunkName: "locale_pl_PL_46json_11e14e3f" */),
      cache: true
    }
  ],
  fi: [
    {
      key: "locale_fi_FI_46json_b95a6e4f",
      load: () => import("#nuxt-i18n/b95a6e4f" /* webpackChunkName: "locale_fi_FI_46json_b95a6e4f" */),
      cache: true
    }
  ],
  da: [
    {
      key: "locale_da_DK_46json_890dd23f",
      load: () => import("#nuxt-i18n/890dd23f" /* webpackChunkName: "locale_da_DK_46json_890dd23f" */),
      cache: true
    }
  ],
  nl: [
    {
      key: "locale_nl_NL_46json_7454e39f",
      load: () => import("#nuxt-i18n/7454e39f" /* webpackChunkName: "locale_nl_NL_46json_7454e39f" */),
      cache: true
    }
  ],
  no: [
    {
      key: "locale_nb_NO_46json_37fb8667",
      load: () => import("#nuxt-i18n/37fb8667" /* webpackChunkName: "locale_nb_NO_46json_37fb8667" */),
      cache: true
    }
  ],
  cs: [
    {
      key: "locale_cs_CZ_46json_3f8b5c7a",
      load: () => import("#nuxt-i18n/3f8b5c7a" /* webpackChunkName: "locale_cs_CZ_46json_3f8b5c7a" */),
      cache: true
    }
  ],
  ru: [
    {
      key: "locale_ru_RU_46json_562781f6",
      load: () => import("#nuxt-i18n/562781f6" /* webpackChunkName: "locale_ru_RU_46json_562781f6" */),
      cache: true
    }
  ],
  tr: [
    {
      key: "locale_tr_TR_46json_6efd8f34",
      load: () => import("#nuxt-i18n/6efd8f34" /* webpackChunkName: "locale_tr_TR_46json_6efd8f34" */),
      cache: true
    }
  ],
  zh: [
    {
      key: "locale_zh_CN_46json_cee42ed3",
      load: () => import("#nuxt-i18n/cee42ed3" /* webpackChunkName: "locale_zh_CN_46json_cee42ed3" */),
      cache: true
    }
  ],
  ja: [
    {
      key: "locale_ja_JP_46json_5eb23d62",
      load: () => import("#nuxt-i18n/5eb23d62" /* webpackChunkName: "locale_ja_JP_46json_5eb23d62" */),
      cache: true
    }
  ],
  ko: [
    {
      key: "locale_ko_KR_46json_6e0114f2",
      load: () => import("#nuxt-i18n/6e0114f2" /* webpackChunkName: "locale_ko_KR_46json_6e0114f2" */),
      cache: true
    }
  ]
}

export const vueI18nConfigs = [
  () => import("#nuxt-i18n/30d4911d" /* webpackChunkName: "config_i18n_46config_46ts_30d4911d" */)
]

export const nuxtI18nOptions = {
  restructureDir: false,
  experimental: {
    localeDetector: "",
    switchLocalePathLinkSSR: false,
    autoImportTranslationFunctions: false,
    typedPages: true,
    typedOptionsAndMessages: false,
    generatedLocaleFilePathFormat: "absolute",
    alternateLinkCanonicalQueries: false,
    hmr: true
  },
  bundle: {
    compositionOnly: true,
    runtimeOnly: false,
    fullInstall: true,
    dropMessageCompiler: false,
    optimizeTranslationDirective: false
  },
  compilation: {
    strictMessage: false,
    escapeHtml: false
  },
  customBlocks: {
    defaultSFCLang: "json",
    globalSFCScope: false
  },
  locales: [
    {
      code: "en",
      language: "en-GB",
      isoUnder: "en_GB",
      hrefLang: "en",
      tag: "en",
      lang: "en",
      url: "en",
      baseUrl: "BASE_URL_EN",
      rwdUrl: "PWA_URL_EN",
      name: "English",
      enName: "English",
      isCatchallLocale: true,
      files: [
        "/build/src/locales/en_GB.json"
      ]
    },
    {
      code: "fr",
      language: "fr-FR",
      isoUnder: "fr_FR",
      hrefLang: "fr",
      tag: "fr",
      lang: "fr",
      url: "fr",
      baseUrl: "BASE_URL_FR",
      rwdUrl: "PWA_URL_FR",
      name: "Français",
      enName: "French",
      files: [
        "/build/src/locales/fr_FR.json"
      ]
    },
    {
      code: "de",
      language: "de-DE",
      isoUnder: "de_DE",
      hrefLang: "de",
      tag: "de",
      lang: "de",
      url: "de",
      baseUrl: "BASE_URL_DE",
      rwdUrl: "PWA_URL_DE",
      name: "Deutsch",
      enName: "German",
      files: [
        "/build/src/locales/de_DE.json"
      ]
    },
    {
      code: "es",
      language: "es-ES",
      isoUnder: "es_ES",
      hrefLang: "es",
      tag: "es",
      lang: "es",
      url: "es",
      baseUrl: "BASE_URL_ES",
      rwdUrl: "PWA_URL_ES",
      name: "Español",
      enName: "Spanish",
      files: [
        "/build/src/locales/es_ES.json"
      ]
    },
    {
      code: "it",
      language: "it-IT",
      isoUnder: "it_IT",
      hrefLang: "it",
      tag: "it",
      lang: "it",
      url: "it",
      baseUrl: "BASE_URL_IT",
      rwdUrl: "PWA_URL_IT",
      name: "Italiano",
      enName: "Italian",
      files: [
        "/build/src/locales/it_IT.json"
      ]
    },
    {
      code: "pt",
      language: "pt-PT",
      isoUnder: "pt_PT",
      hrefLang: "pt",
      tag: "pt-PT",
      lang: "pt",
      url: "pt",
      baseUrl: "BASE_URL_PT",
      rwdUrl: "PWA_URL_PT",
      name: "Português",
      enName: "Portuguese",
      files: [
        "/build/src/locales/pt_PT.json"
      ]
    },
    {
      code: "br",
      language: "pt-BR",
      isoUnder: "pt_BR",
      hrefLang: "pt-br",
      tag: "pt-BR",
      lang: "br",
      url: "br",
      baseUrl: "BASE_URL_BR",
      rwdUrl: "PWA_URL_BR",
      name: "Português (Brasil)",
      enName: "Brazilian Portuguese",
      files: [
        "/build/src/locales/pt_BR.json"
      ]
    },
    {
      code: "sv",
      language: "sv-SE",
      isoUnder: "sv_SE",
      hrefLang: "sv",
      tag: "sv",
      lang: "sv",
      url: "sv",
      baseUrl: "BASE_URL_SV",
      rwdUrl: "PWA_URL_SV",
      name: "Svenska",
      enName: "Swedish",
      files: [
        "/build/src/locales/sv_SE.json"
      ]
    },
    {
      code: "pl",
      language: "pl-PL",
      isoUnder: "pl_PL",
      hrefLang: "pl",
      tag: "pl",
      lang: "pl",
      url: "pl",
      baseUrl: "BASE_URL_PL",
      rwdUrl: "PWA_URL_PL",
      name: "Polski",
      enName: "Polish",
      files: [
        "/build/src/locales/pl_PL.json"
      ]
    },
    {
      code: "fi",
      language: "fi-FI",
      isoUnder: "fi_FI",
      hrefLang: "fi",
      tag: "fi",
      lang: "fi",
      url: "fi",
      baseUrl: "BASE_URL_FI",
      rwdUrl: "PWA_URL_FI",
      name: "Suomi",
      enName: "Finnish",
      files: [
        "/build/src/locales/fi_FI.json"
      ]
    },
    {
      code: "da",
      language: "da-DK",
      isoUnder: "da_DK",
      hrefLang: "da",
      tag: "da",
      lang: "da",
      url: "da",
      baseUrl: "BASE_URL_DA",
      rwdUrl: "PWA_URL_DA",
      name: "Dansk",
      enName: "Danish",
      files: [
        "/build/src/locales/da_DK.json"
      ]
    },
    {
      code: "nl",
      language: "nl-NL",
      isoUnder: "nl_NL",
      hrefLang: "nl",
      tag: "nl",
      lang: "nl",
      url: "nl",
      baseUrl: "BASE_URL_NL",
      rwdUrl: "PWA_URL_NL",
      name: "Nederlands",
      enName: "Dutch",
      files: [
        "/build/src/locales/nl_NL.json"
      ]
    },
    {
      code: "no",
      language: "nb-NO",
      isoUnder: "nb_NO",
      hrefLang: "no",
      tag: "no",
      lang: "nb",
      url: "no",
      baseUrl: "BASE_URL_NO",
      rwdUrl: "PWA_URL_NO",
      name: "Norsk",
      enName: "Norwegian",
      files: [
        "/build/src/locales/nb_NO.json"
      ]
    },
    {
      code: "cs",
      language: "cs-CZ",
      isoUnder: "cs_CZ",
      hrefLang: "cs",
      tag: "cs",
      lang: "cs",
      url: "cs",
      baseUrl: "BASE_URL_CS",
      rwdUrl: "PWA_URL_CS",
      name: "český",
      enName: "Czech",
      files: [
        "/build/src/locales/cs_CZ.json"
      ]
    },
    {
      code: "ru",
      language: "ru-RU",
      isoUnder: "ru_RU",
      hrefLang: "ru",
      tag: "ru",
      lang: "ru",
      url: "ru",
      baseUrl: "BASE_URL_RU",
      rwdUrl: "PWA_URL_RU",
      name: "Русский",
      enName: "Russian",
      files: [
        "/build/src/locales/ru_RU.json"
      ]
    },
    {
      code: "tr",
      language: "tr-TR",
      isoUnder: "tr_TR",
      hrefLang: "tr",
      tag: "tr",
      lang: "tr",
      url: "tr",
      baseUrl: "BASE_URL_TR",
      rwdUrl: "PWA_URL_TR",
      name: "Türk",
      enName: "Turkish",
      files: [
        "/build/src/locales/tr_TR.json"
      ]
    },
    {
      code: "zh",
      language: "zh-CN",
      isoUnder: "zh_CN",
      hrefLang: "zh-cn",
      tag: "zh",
      lang: "zh",
      url: "zh",
      baseUrl: "BASE_URL_ZH",
      rwdUrl: "PWA_URL_ZH",
      name: "中文",
      enName: "Chinese",
      files: [
        "/build/src/locales/zh_CN.json"
      ]
    },
    {
      code: "ja",
      language: "ja-JP",
      isoUnder: "ja_JP",
      hrefLang: "ja",
      tag: "ja",
      lang: "ja",
      url: "ja",
      baseUrl: "BASE_URL_JA",
      rwdUrl: "PWA_URL_JA",
      name: "日本語",
      enName: "Japanese",
      files: [
        "/build/src/locales/ja_JP.json"
      ]
    },
    {
      code: "ko",
      language: "ko-KR",
      isoUnder: "ko_KR",
      hrefLang: "ko",
      tag: "ko",
      lang: "ko",
      url: "ko",
      baseUrl: "BASE_URL_KO",
      rwdUrl: "PWA_URL_KO",
      name: "한국어",
      enName: "Korean",
      files: [
        "/build/src/locales/ko_KR.json"
      ]
    }
  ],
  defaultLocale: "en",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  trailingSlash: true,
  defaultLocaleRouteNameSuffix: "default",
  strategy: "prefix_except_default",
  lazy: true,
  langDir: "locales/",
  rootRedirect: undefined,
  detectBrowserLanguage: {
    alwaysRedirect: false,
    cookieCrossOrigin: false,
    cookieDomain: null,
    cookieKey: "i18n_redirected",
    cookieSecure: false,
    fallbackLocale: "",
    redirectOn: "root",
    useCookie: true
  },
  differentDomains: false,
  baseUrl: "",
  customRoutes: "page",
  pages: {},
  skipSettingLocaleOnNavigate: false,
  types: "composition",
  debug: false,
  parallelPlugin: false,
  multiDomainLocales: false,
  legacy: false,
  locale: "en",
  fallbackLocale: "en",
  precompile: {
    strictMessage: false
  },
  defaultLocaleObj: {
    code: "en",
    language: "en-GB",
    isoUnder: "en_GB",
    hrefLang: "en",
    file: "en_GB.json",
    tag: "en",
    lang: "en",
    url: "en",
    baseUrl: "BASE_URL_EN",
    rwdUrl: "PWA_URL_EN",
    name: "English",
    enName: "English",
    isCatchallLocale: true
  },
  i18nModules: []
}

export const normalizedLocales = [
  {
    code: "en",
    language: "en-GB",
    isoUnder: "en_GB",
    hrefLang: "en",
    tag: "en",
    lang: "en",
    url: "en",
    baseUrl: "BASE_URL_EN",
    rwdUrl: "PWA_URL_EN",
    name: "English",
    enName: "English",
    isCatchallLocale: true,
    files: [
      {
        path: "/build/src/locales/en_GB.json",
        cache: undefined
      }
    ]
  },
  {
    code: "fr",
    language: "fr-FR",
    isoUnder: "fr_FR",
    hrefLang: "fr",
    tag: "fr",
    lang: "fr",
    url: "fr",
    baseUrl: "BASE_URL_FR",
    rwdUrl: "PWA_URL_FR",
    name: "Français",
    enName: "French",
    files: [
      {
        path: "/build/src/locales/fr_FR.json",
        cache: undefined
      }
    ]
  },
  {
    code: "de",
    language: "de-DE",
    isoUnder: "de_DE",
    hrefLang: "de",
    tag: "de",
    lang: "de",
    url: "de",
    baseUrl: "BASE_URL_DE",
    rwdUrl: "PWA_URL_DE",
    name: "Deutsch",
    enName: "German",
    files: [
      {
        path: "/build/src/locales/de_DE.json",
        cache: undefined
      }
    ]
  },
  {
    code: "es",
    language: "es-ES",
    isoUnder: "es_ES",
    hrefLang: "es",
    tag: "es",
    lang: "es",
    url: "es",
    baseUrl: "BASE_URL_ES",
    rwdUrl: "PWA_URL_ES",
    name: "Español",
    enName: "Spanish",
    files: [
      {
        path: "/build/src/locales/es_ES.json",
        cache: undefined
      }
    ]
  },
  {
    code: "it",
    language: "it-IT",
    isoUnder: "it_IT",
    hrefLang: "it",
    tag: "it",
    lang: "it",
    url: "it",
    baseUrl: "BASE_URL_IT",
    rwdUrl: "PWA_URL_IT",
    name: "Italiano",
    enName: "Italian",
    files: [
      {
        path: "/build/src/locales/it_IT.json",
        cache: undefined
      }
    ]
  },
  {
    code: "pt",
    language: "pt-PT",
    isoUnder: "pt_PT",
    hrefLang: "pt",
    tag: "pt-PT",
    lang: "pt",
    url: "pt",
    baseUrl: "BASE_URL_PT",
    rwdUrl: "PWA_URL_PT",
    name: "Português",
    enName: "Portuguese",
    files: [
      {
        path: "/build/src/locales/pt_PT.json",
        cache: undefined
      }
    ]
  },
  {
    code: "br",
    language: "pt-BR",
    isoUnder: "pt_BR",
    hrefLang: "pt-br",
    tag: "pt-BR",
    lang: "br",
    url: "br",
    baseUrl: "BASE_URL_BR",
    rwdUrl: "PWA_URL_BR",
    name: "Português (Brasil)",
    enName: "Brazilian Portuguese",
    files: [
      {
        path: "/build/src/locales/pt_BR.json",
        cache: undefined
      }
    ]
  },
  {
    code: "sv",
    language: "sv-SE",
    isoUnder: "sv_SE",
    hrefLang: "sv",
    tag: "sv",
    lang: "sv",
    url: "sv",
    baseUrl: "BASE_URL_SV",
    rwdUrl: "PWA_URL_SV",
    name: "Svenska",
    enName: "Swedish",
    files: [
      {
        path: "/build/src/locales/sv_SE.json",
        cache: undefined
      }
    ]
  },
  {
    code: "pl",
    language: "pl-PL",
    isoUnder: "pl_PL",
    hrefLang: "pl",
    tag: "pl",
    lang: "pl",
    url: "pl",
    baseUrl: "BASE_URL_PL",
    rwdUrl: "PWA_URL_PL",
    name: "Polski",
    enName: "Polish",
    files: [
      {
        path: "/build/src/locales/pl_PL.json",
        cache: undefined
      }
    ]
  },
  {
    code: "fi",
    language: "fi-FI",
    isoUnder: "fi_FI",
    hrefLang: "fi",
    tag: "fi",
    lang: "fi",
    url: "fi",
    baseUrl: "BASE_URL_FI",
    rwdUrl: "PWA_URL_FI",
    name: "Suomi",
    enName: "Finnish",
    files: [
      {
        path: "/build/src/locales/fi_FI.json",
        cache: undefined
      }
    ]
  },
  {
    code: "da",
    language: "da-DK",
    isoUnder: "da_DK",
    hrefLang: "da",
    tag: "da",
    lang: "da",
    url: "da",
    baseUrl: "BASE_URL_DA",
    rwdUrl: "PWA_URL_DA",
    name: "Dansk",
    enName: "Danish",
    files: [
      {
        path: "/build/src/locales/da_DK.json",
        cache: undefined
      }
    ]
  },
  {
    code: "nl",
    language: "nl-NL",
    isoUnder: "nl_NL",
    hrefLang: "nl",
    tag: "nl",
    lang: "nl",
    url: "nl",
    baseUrl: "BASE_URL_NL",
    rwdUrl: "PWA_URL_NL",
    name: "Nederlands",
    enName: "Dutch",
    files: [
      {
        path: "/build/src/locales/nl_NL.json",
        cache: undefined
      }
    ]
  },
  {
    code: "no",
    language: "nb-NO",
    isoUnder: "nb_NO",
    hrefLang: "no",
    tag: "no",
    lang: "nb",
    url: "no",
    baseUrl: "BASE_URL_NO",
    rwdUrl: "PWA_URL_NO",
    name: "Norsk",
    enName: "Norwegian",
    files: [
      {
        path: "/build/src/locales/nb_NO.json",
        cache: undefined
      }
    ]
  },
  {
    code: "cs",
    language: "cs-CZ",
    isoUnder: "cs_CZ",
    hrefLang: "cs",
    tag: "cs",
    lang: "cs",
    url: "cs",
    baseUrl: "BASE_URL_CS",
    rwdUrl: "PWA_URL_CS",
    name: "český",
    enName: "Czech",
    files: [
      {
        path: "/build/src/locales/cs_CZ.json",
        cache: undefined
      }
    ]
  },
  {
    code: "ru",
    language: "ru-RU",
    isoUnder: "ru_RU",
    hrefLang: "ru",
    tag: "ru",
    lang: "ru",
    url: "ru",
    baseUrl: "BASE_URL_RU",
    rwdUrl: "PWA_URL_RU",
    name: "Русский",
    enName: "Russian",
    files: [
      {
        path: "/build/src/locales/ru_RU.json",
        cache: undefined
      }
    ]
  },
  {
    code: "tr",
    language: "tr-TR",
    isoUnder: "tr_TR",
    hrefLang: "tr",
    tag: "tr",
    lang: "tr",
    url: "tr",
    baseUrl: "BASE_URL_TR",
    rwdUrl: "PWA_URL_TR",
    name: "Türk",
    enName: "Turkish",
    files: [
      {
        path: "/build/src/locales/tr_TR.json",
        cache: undefined
      }
    ]
  },
  {
    code: "zh",
    language: "zh-CN",
    isoUnder: "zh_CN",
    hrefLang: "zh-cn",
    tag: "zh",
    lang: "zh",
    url: "zh",
    baseUrl: "BASE_URL_ZH",
    rwdUrl: "PWA_URL_ZH",
    name: "中文",
    enName: "Chinese",
    files: [
      {
        path: "/build/src/locales/zh_CN.json",
        cache: undefined
      }
    ]
  },
  {
    code: "ja",
    language: "ja-JP",
    isoUnder: "ja_JP",
    hrefLang: "ja",
    tag: "ja",
    lang: "ja",
    url: "ja",
    baseUrl: "BASE_URL_JA",
    rwdUrl: "PWA_URL_JA",
    name: "日本語",
    enName: "Japanese",
    files: [
      {
        path: "/build/src/locales/ja_JP.json",
        cache: undefined
      }
    ]
  },
  {
    code: "ko",
    language: "ko-KR",
    isoUnder: "ko_KR",
    hrefLang: "ko",
    tag: "ko",
    lang: "ko",
    url: "ko",
    baseUrl: "BASE_URL_KO",
    rwdUrl: "PWA_URL_KO",
    name: "한국어",
    enName: "Korean",
    files: [
      {
        path: "/build/src/locales/ko_KR.json",
        cache: undefined
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false
export const hasPages = true

export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18nInternal"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
/** client **/

/** client-end **/